import { Component, OnInit } from '@angular/core';
import { UtilHelper } from 'lib-smart-core';
import { Constantes } from '../../../environments/constantes';

@Component({
  selector: 'menu-component',
  templateUrl: './menu.component.html'
})

export class MenuComponent implements OnInit {

  imageDefaultProfile: string = UtilHelper.no_image_user;

  currentUser: any;

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem(Constantes.currentUser));
    if (this.currentUser) {
      if (this.currentUser.usuario.image != "false" && this.currentUser.usuario.image != null && this.currentUser.usuario.image != undefined) {
        this.imageDefaultProfile = UtilHelper.getUsuarioImage(this.currentUser.usuario);
      }
    }
  }

}