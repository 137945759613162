import { Location } from "@angular/common";
import { Injectable, Injector } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AlertService, ColorUtil, CONSTANTES, Constantes } from "../../public-api";
import { Usuario } from "../models/usuario.model";
import { FilaHelper } from "../shared/helpers/fila.helper";
import { Empresa } from './../models/empresa.model';
import { UtilHelper } from './util.helper';

@Injectable()
export abstract class AbstractComponent {

  protected location: Location;
  protected router: Router;
  protected route: ActivatedRoute;
  protected formBuilder: FormBuilder;
  protected alertService: AlertService;
  protected constantes: Constantes;
  protected subscriptions: Subscription = new Subscription();

  public searchString: string;
  public searchParams: any;
  public filaHelper: FilaHelper = new FilaHelper();

  constructor(injector: Injector) {
    this.location = injector.get(Location);
    this.router = injector.get(Router);
    this.route = injector.get(ActivatedRoute);
    this.alertService = injector.get(AlertService);
    this.formBuilder = injector.get(FormBuilder);
    this.constantes = injector.get(CONSTANTES);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public get emailOwners() {
    return [
      'vinicius@smartline.com.br',
      'marcelo@smartline.com.br',
      'daniel@smartline.com.br'
    ];
  }

  getUsuarioImage(usuario: Usuario): string {
    return UtilHelper.getUsuarioImage(usuario);
  }

  getEmpresaImage(empresa: Empresa): string {
    return UtilHelper.getEmpresaImage(empresa);
  }

  getDataAtual(): Date {
    var dataAtual = new Date();
    dataAtual.setDate(dataAtual.getDate() + 1);
    return dataAtual;
  }

  getCurrentUserUser(): Usuario {
    return JSON.parse(localStorage.getItem(this.constantes['currentUser'])).usuario;
  }

  getCurrentUser(): any {
    return JSON.parse(localStorage.getItem(this.constantes['currentUser']));
  }

  getImpersonateSession(): any {
    let currentUser = this.getCurrentUser();
    return currentUser.impersonate;
  }

  getEmpresaSession(): any {
    let currentUser = this.getCurrentUser();
    return currentUser.usuario.empresa;
  }

  getIDEmpresaSession(): any {
    var currentUser = this.getCurrentUser();
    return currentUser.usuario.empresa._id;
  }

  getIDCurrentUserSession(): any {
    var currentUser = this.getCurrentUser();
    return currentUser.usuario._id;
  }

  getCurrentUserTV(): any {
    return JSON.parse(localStorage.getItem(this.constantes['currentUser']));
  }

  getIDFranquiaSession(): any {
    const currentUserTV = this.getCurrentUserTV();
    return currentUserTV.franquia._id;
  }

  getNomeCurrentUserSession(): any {
    const currentUser = this.getCurrentUser();
    return currentUser.usuario.nome;
  }

  getEmailUsuarioLogado(): any {
    const currentUser = this.getCurrentUser();
    return currentUser.usuario.email;
  }

  getParam(key: string): any {
    let result = null;
    this.route.params.forEach((params: Params) => {
      result = params[key];
    });
    return result;
  }

  getQueryParam(key: string) {
    let result = null;
    this.route.queryParamMap.subscribe(queryParams => {
      result = queryParams.get(key);
    });
    return result;
  }

  goBack(): void {
    this.location.back();
  }

  isAdmin() {
    const user = this.getCurrentUserUser();
    if (user) {
      return user.admin;
    }
    return false;
  }

  isManager() {
    const user = this.getCurrentUserUser();
    if (user) {
      return user.manager;
    }
    return false;
  }

  keyPressOnlyNumbers(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyPressOnlyLetters(event: any) {
    const pattern = /[A-Z\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // Validacao com Reactive Forms

  formGroup: FormGroup;

  isFieldValid(field: string, formGroup?: FormGroup) {
    if (!formGroup) {
      return !this.formGroup.get(field).valid && this.formGroup.get(field).touched;
    } else {
      return !formGroup.get(field).valid && formGroup.get(field).touched;
    }
  }

  displayFieldCss(field: string, formGroup?: FormGroup) {
    return {
      'has-error': (formGroup ? this.isFieldValid(field, formGroup) : this.isFieldValid(field)),
      'has-feedback': (formGroup ? this.isFieldValid(field, formGroup) : this.isFieldValid(field))
    };
  }

  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  reset() {
    this.formGroup.reset();
  }

  getNomeChamada(tipoChamada: string) {
    return this.filaHelper.getNomeChamada(tipoChamada);
  }

  hexToRGBA(hexColor: string, alpha: number): string {
    return ColorUtil.adjustAlpha(hexColor, alpha);
  }

}
