import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../commons/abstract.service';

@Injectable({ providedIn: 'root' })
export class GeocodeHereService extends AbstractService<any> {

  getURLBase(): string {
    return '/integrations/geocode/here/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: any): void {
    delete obj._id;
  }
  preUpdate(obj: any): void {
  }

  geocodeByPosition(latitude: number, longitude: number): Observable<any> {
    return super.get(`position/${latitude}/${longitude}`);
  }

  geocodeByCEP(cep: string): Observable<any> {
    return super.get(`cep/${cep}`);
  }

}
