import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, Franquia, FranquiaService, Sender, SenderService } from 'lib-smart-core';

@Component({
  templateUrl: './franquia-forms.component.html'
})

export class FranquiaFormsComponent extends AbstractComponent implements OnInit {

  franquia: Franquia;
  isNew = true;

  senders: Sender[];

  constructor(
    private senderService: SenderService,
    private franquiaService: FranquiaService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.franquia = {} as Franquia;
    const idEmpresa: string = super.getParam('idEmpresa');
    if (idEmpresa) {
      this.franquia = {} as Franquia;
      this.franquia.empresa = { _id: idEmpresa } as Empresa;
    } else {
      const id: string = super.getParam('id');
      if (id) {
        this.isNew = false;
        this.getFranquiaPorId(id.toString());
      }
    }
    this.getSenders();
  }

  getSenders() {
    this.senderService.getAll().subscribe(
      (senders: Sender[]) => {
        this.senders = senders;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getFranquiaPorId(id: string) {
    return this.franquiaService.getById(id).subscribe(
      (franquia) => {
        this.franquia = franquia;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  onSubmit(): void {
    console.log('on submit add franquia >> ', this.franquia);

    if (!!this.franquia.sender) {
      this.franquia.senderEnabled = true;
    }

    if (this.isNew) {
      this.franquiaService.create(this.franquia)
        .subscribe(
          franquia => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    } else {
      this.franquiaService.update(this.franquia)
        .subscribe(
          franquia => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    }

  }
}
