import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Chart } from "chart.js";
import { ChartHelper } from '../../../commons/chart.helper';

@Component({
  selector: 'chart-pie',
  template: `<canvas id="chartsjs-{{id}}" width="{{width}}" height="{{height}}"></canvas>`
})

export class ChartPieComponent implements OnInit, AfterViewInit {

  @Input() id: string = '';
  @Input() width?: number = 400;
  @Input() height?: number = 400;

  canvas: any;
  ctx: any;
  pie: any;

  constructor() {}

  ngAfterViewInit(): void {
    this.canvas = document.getElementById(`chartsjs-${this.id}`);
    this.ctx = this.canvas.getContext('2d');
    this.chartPie();
  }

  ngOnInit() {}

  chartPie() {
    if (!!this.pie) {
      this.pie.destroy();
    }

    this.pie = new Chart(
      this.ctx,
      {
        type: 'pie',
        data: {
          labels: [
            'Normal',
            'Preferêncial',
            'Estoque'
          ],
          datasets: [{
            data: [300, 50, 100],
            backgroundColor: Object.values(ChartHelper.COLORS),
          }]
        },
        options: {}
      }
    )
  }

}
