<div class="row">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Estabelecimentos</h5>
        <div class="ibox-tools">
          <a [routerLink]="['/franquia/add/empresa', idEmpresa]" class="btn btn-primary">
            <i class="fa fa-plus"></i> Adicionar Estabelecimento</a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Nome/Marca</th>
                <th>Código</th>
                <th>CNPJ</th>
                <th>Cidade/UF</th>
                <th>Sender</th>
                <th>Bloqueado?</th>
                <th width="14%"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let franquia of franquias">
                <td>{{franquia.nome}} [{{franquia.nomeFantasia}}]</td>
                <td>{{franquia.codigo}}</td>
                <td>{{franquia.cpfCnpj}}</td>
                <td>{{franquia.cidade}}/{{franquia.estado}}</td>
                <td>
                  <small>{{!!franquia?.sender ? franquia.sender.name : sender?.name + ' (padrão empresa)'}}</small>
                </td>
                <td>
                  <span class="label label-danger" style="margin-right: 2px;"
                    *ngIf="franquia.bloqueado">Bloqueado</span>
                  <span class="label label-primary" style="margin-right: 2px;"
                    *ngIf="!franquia.bloqueado">Liberado</span>
                </td>
                <td class="text-right">
                  <a class="btn btn-white btn-sm btn-bitbucket" [routerLink]="['/franquia/edit/', franquia._id]">
                    <i class="fa fa-edit"></i>
                  </a>
                  <a *ngIf="!franquia.bloqueado" class="btn btn-warning btn-sm btn-bitbucket" data-toggle="modal"
                    data-target="#modalBloquearFranquia" (click)="selecionar(franquia)">
                    <i class="fa fa-lock"></i>
                  </a>
                  <a *ngIf="franquia.bloqueado" class="btn btn-warning btn-sm btn-bitbucket" data-toggle="modal"
                    data-target="#modalDesbloquearFranquia" (click)="selecionar(franquia)">
                    <i class="fa fa-unlock"></i>
                  </a>
                  <a class="btn btn-danger btn-sm btn-bitbucket" data-toggle="modal" data-target="#modalRemoveFranquia"
                    (click)="selecionar(franquia)">
                    <i class="fa fa-trash"></i>
                  </a>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="col-lg-4">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Alterar Logotipo da Empresa</h5>
      </div>
      <div class="ibox-content">
        <div class="form-group" style="display: none">
          <input type="file" class="form-control" id="localImage" (change)="onChange($event)" accept=".jpg, .png">
        </div>
        <div class="panel-body text-center">
          <img *ngIf="empresa?.image" src="{{empresa?.image}}" style="max-width: 100%;" />
        </div>
        <hr class="m-y-0">
        <div class="panel-body text-center">
          <button type="button" class="btn btn-primary" (click)="openFileBrowser($event)">Alterar</button>&nbsp;
          <button type="button" class="btn" (click)="removerImagem()">
            <i class="fa fa-trash"></i>
          </button>
          <div class="m-t-2 text-muted font-size-12">JPG ou PNG. Tamano máximo 1MB</div>
        </div>
      </div>
    </div>
  </div> -->
</div>

<div class="modal inmodal" id="modalRemoveFranquia" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Remover Estabelecimento</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita! Tem certeza que deseja <strong>REMOVER</strong> o estabelecimento
            <strong>{{franquiaSelecionada?.nome}}</strong>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-default" (click)="delete()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modalBloquearFranquia" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Bloquear Estabelecimento</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Tem certeza que deseja <strong>BLOQUEAR</strong> o estabelecimento
            <strong>{{franquiaSelecionada?.nome}}</strong>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-default" (click)="bloquear()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modalDesbloquearFranquia" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Desbloquear Estabelecimento</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Tem certeza que deseja <strong>DESBLOQUEAR</strong> o estabelecimento
            <strong>{{franquiaSelecionada?.nome}}</strong>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-default" (click)="desbloquear()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>