import { NgModule } from '@angular/core';
import { LibSmartCoreComponent } from './lib-smart-core.component';



@NgModule({
  declarations: [
    LibSmartCoreComponent
  ],
  imports: [
  ],
  exports: [
    LibSmartCoreComponent
  ]
})
export class LibSmartCoreModule { }
