import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Grupo } from '../models/grupo.model';

@Injectable({ providedIn: 'root' })
export class GrupoService extends AbstractService<Grupo> {

  getURLBase(): string {
    return '/grupos/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }
  preCreate(obj: Grupo): void {
    delete obj._id;
  }
  preUpdate(obj: Grupo): void {
  }

  associarFranquia(idGrupo: string, idFranquia: string) {
    return this.http.get(this.getURLBase() + 'associar/franquia/' + idFranquia + '/grupo/' + idGrupo);
  }

  desassociarFranquia(idGrupo: string, idFranquia: string) {
    return this.http.get(this.getURLBase() + 'desassociar/franquia/' + idFranquia + '/grupo/' + idGrupo);
  }



}