import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, Usuario, UsuarioService } from 'lib-smart-core';
import * as XLSX from 'xlsx';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'usuario-lista',
  templateUrl: './usuario-lista.component.html'
})
export class UsuarioListaComponent extends AbstractComponent implements OnInit {

  fileUploaded: File;
  idEmpresa: string;
  idGrupo: string;

  usuarios: Usuario[];
  usuarioSelecionado: Usuario;

  @Input() tipo: string;

  constructor(
    private usuarioService: UsuarioService
    ,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let idEmpresa: string = super.getParam('idEmpresa');
    if (idEmpresa) {
      this.idEmpresa = idEmpresa;
      this.getUsuariosPorEmpresa(idEmpresa);
    } else {
      let idGrupo: string = super.getParam('idGrupo');
      if (idGrupo) {
        this.idGrupo = idGrupo;
        this.getUsuariosPorGrupo(idGrupo);

      }
    }
  }

  getUsuariosPorEmpresa(idEmpresa: string) {
    return this.usuarioService.getUsuariosPorEmpresa(idEmpresa).subscribe(
      (usuarios: Usuario[]) => {
        this.usuarios = usuarios
      },
      err => {
        console.log(err)
      }
    )
  }

  getUsuariosPorGrupo(idGrupo: string) {
    return this.usuarioService.getUsuariosPorGrupo(idGrupo).subscribe(
      (usuarios: Usuario[]) => {
        this.usuarios = usuarios
      },
      err => {
        console.log(err)
      }
    )
  }

  selecionar(usuario: Usuario) {
    this.usuarioSelecionado = usuario;
  }

  delete(): void {
    this.usuarioService.delete(this.usuarioSelecionado._id).subscribe(
      () => {
        if (this.idEmpresa) {
          this.getUsuariosPorEmpresa(this.idEmpresa);
        } else if (this.idGrupo) {
          this.getUsuariosPorGrupo(this.idGrupo);
        }
        this.alertService.success('Operação realizada com sucesso');
      },
      err => {
        console.log(err);
        this.alertService.error(err.error.message);
      }
    );
  }

  bloquear(): void {
    this.usuarioService.bloquear(this.usuarioSelecionado._id).subscribe(
      () => {
        if (this.idEmpresa) {
          this.getUsuariosPorEmpresa(this.idEmpresa);
        } else if (this.idGrupo) {
          this.getUsuariosPorGrupo(this.idGrupo);
        }
        this.alertService.success('Operação realizada com sucesso');
      },
      err => {
        console.log(err);
        this.alertService.error(err.error.message);
      }
    );
  }

  desbloquear(): void {
    this.usuarioService.desbloquear(this.usuarioSelecionado._id).subscribe(
      () => {
        if (this.idEmpresa) {
          this.getUsuariosPorEmpresa(this.idEmpresa);
        } else if (this.idGrupo) {
          this.getUsuariosPorGrupo(this.idGrupo);
        }
        this.alertService.success('Operação realizada com sucesso');
      },
      err => {
        console.log(err);
        this.alertService.error(err.error.message);
      }
    );
  }

  uploadedFile(event) {
    this.fileUploaded = event.target.files[0];
    this.uploadExcel();
  }

  uploadExcel() {
    let readFile = new FileReader();
    readFile.onload = (e) => {
      let storeData: any = readFile.result;
      var data = new Uint8Array(storeData);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary" });
      var first_sheet_name = workbook.SheetNames[0];
      let worksheet = workbook.Sheets[first_sheet_name];
      let jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      // let jsonDataStr = JSON.stringify(jsonData);

      const usuarios = jsonData.map((json: any) => {
        const user = {} as Usuario;
        user.nome = json.nome;
        user.email = json.email;
        user.root = false;
        user.admin = false;
        user.manager = false;
        user.empresa = { _id: this.idEmpresa } as Empresa;
        return user;
      })

      console.log(usuarios);

      this.usuarioService.createMany(usuarios).subscribe();
    }
    readFile.readAsArrayBuffer(this.fileUploaded);
  }

  loginImpersonate(email: string) {
    const urlRedirect = `${environment.controleUrl}/redirect?email=${email}&idEmpresa=${this.idEmpresa}`;
    window.open(urlRedirect, '_blank');
  }

}