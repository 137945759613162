import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Feedback } from '../../models/feedback.model';

@Injectable({ providedIn: 'root' })
export class PublicFeedbackService {

  getURLBase(): string {
    return '/public/feedbacks/';
  }

  constructor(private http: HttpClient) {
  }

  create(obj: Feedback): Observable<Feedback> {
    return this.http.post<Feedback>(this.getURLBase() + 'create', obj);
  }

  update(obj: Feedback): Observable<Feedback> {
    return this.http.put<Feedback>(this.getURLBase() + 'update', obj);
  }

}
