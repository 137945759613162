import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../commons/abstract.service';

@Injectable({ providedIn: 'root' })
export class RelatorioRankingAtendimentoService extends AbstractService<any> {

  getURLBase(): string {
    return '/reports/relatorio-ranking-atendimento/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: any): void {
    delete obj._id;
  }
  preUpdate(obj: any): void {
  }

}