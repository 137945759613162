import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent } from 'lib-smart-core';

@Component({
  selector: 'pagamento',
  templateUrl: './pagamento.component.html'
})

export class PagamentoComponent extends AbstractComponent implements OnInit {

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
  }

}