import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Pergunta } from '../models/pergunta.model';

@Injectable({ providedIn: 'root' })
export class PerguntaService extends AbstractService<Pergunta> {

  getURLBase(): string {
    return '/perguntas/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Pergunta): void {
    delete obj._id;
  }
  preUpdate(obj: Pergunta): void {
  }

  getAllPorQuestionario(idQuestionario: string): Observable<Pergunta[] | any> {
    return super.get('list/' + idQuestionario);
  }

  getAllPorQuestionarioPager(page: number, perPage: number, idQuestionario: string): Observable<Pergunta[] | any> {
    return super.get('pager/' + page + '/' + perPage + '/questionario/' + idQuestionario);
  }

}
