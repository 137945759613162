<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Usuário
      <a class="btn btn-primary pull-right"><i class="fa fa-plus"></i> Adicionar</a>
    </h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li class="active">
        <strong>Usuário</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <usuario-lista [tipo]="'empresa'"></usuario-lista>
</div>