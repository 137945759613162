import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Franquia } from '../models/franquia.model';

@Injectable({ providedIn: 'root' })
export class FranquiaService extends AbstractService<Franquia> {

  getURLBase(): string {
    return '/franquias/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }
  preCreate(obj: Franquia): void {
    delete obj._id;
  }
  preUpdate(obj: Franquia): void {
  }

  getFranquiasPorGrupo(idGrupo: string): Observable<Franquia[] | any> {
    return this.http.get(this.getURLBase() + 'grupo/' + idGrupo);
  }

  search(search: string) {
    return this.http.get(this.getURLBase() + 'list/search/' + search);
  }

  bloquear(id: string) {
    return this.http.get(this.getURLBase() + 'bloquear/' + id);
  }

  desbloquear(id: string) {
    return this.http.get(this.getURLBase() + 'desbloquear/' + id);
  }

  searchEmpresa(idEmpresa: string, search: string) {
    return super.get('list/search/empresa/' + idEmpresa + '/' + search);
  }

  getFranquiaPorCodigo(codigo: string) {
    return this.http.get(this.getURLBase() + 'get/franquia/codigo/' + codigo);
  }

  getFranquiasPorEmpresa(idEmpresa): Observable<Array<Franquia> | any> {
    return super.get('empresa/' + idEmpresa);
  }

}