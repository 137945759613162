import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Sender } from '../models/sender.model';

@Injectable({ providedIn: 'root' })
export class SenderService extends AbstractService<Sender> {

  getURLBase(): string {
    return '/sender/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }
  preCreate(obj: Sender): void {
    delete obj._id;
  }
  preUpdate(obj: Sender): void {
  }

}