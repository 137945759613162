import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../commons/abstract.service';
import { Aviso } from '../../models/aviso.model';

@Injectable({ providedIn: 'root' })
export class PublicAvisoService extends AbstractService<Aviso>  {

  getURLBase(): string {
    return '/public/avisos/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Aviso): void {
    delete obj._id;
  }
  preUpdate(obj: Aviso): void {
  }

  getListAvisosFranquia(idFranquia: string): Observable<Aviso[] | any> {
    return this.http.get(this.getURLBase() + 'list/ativos/franquia/' + idFranquia);
  }

}