<div class="row">
  <div class="col-lg-8">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Filtro</h5>
      </div>
      <div class="ibox-content">
        <div class="input-group">
          <span class="input-group-addon" id="sizing-addon2">
            <i class="fa fa-search"></i>
          </span>
          <input type="text" class="form-control shadow-inset-2" #searchBox (keyup)="search(searchBox.value)"
            placeholder="Procurar...">
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="ibox ">
      <div class="ibox-title">
        <h5>Situação</h5>
      </div>
      <div class="ibox-content">
        <div class="text-center">
          <a (click)="loadByStatus('ATIVA')" class="btn btn-primary" href="#modal-form">ATIVA</a>
          <a (click)="loadByStatus('INATIVA')" class="btn btn-danger" href="#modal-form">INATIVA</a>
          <a (click)="loadByStatus('PENDENTE')" class="btn btn-warning" href="#modal-form">PENDENTE</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Empresa ({{pager.total}})</h5>
        <div class="ibox-tools">
          <a [routerLink]="['/empresa/new']" class="btn btn-primary btn-xs"><i class="fa fa-plus"></i> Adicionar</a>
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">

          <table class="table table-striped table-hover">
            <tbody>
              <tr *ngFor="let empresa of pager.list">
                <td class="client-avatar">
                  <img alt="image" src="{{getEmpresaImage(empresa)}}">
                </td>
                <td>
                  <a [routerLink]="['/empresa/detail', empresa._id]" class="client-link">{{empresa.nome}}</a>
                </td>
                <td>{{empresa.cpfCnpj}}</td>
                <td>{{empresa.nomeResponsavel}}</td>
                <td><i class="fa fa-phone"></i> {{empresa.celularResponsavel}}</td>
                <td class="client-status">
                  <span class="label label-primary" *ngIf="empresa?.situacao === 'ATIVA'">ATIVA</span>
                  <span class="label label-danger" *ngIf="empresa?.situacao === 'INATIVA'">INATIVA</span>
                </td>
                <td style="text-align: right;">
                  <a class="btn btn-primary" [routerLink]="['/empresa/detail', empresa._id]" title="Dashboard"><i
                      class="fa fa-home"></i>
                  </a>
                  <a class="btn btn-white" [routerLink]="['/empresa/edit', empresa._id]" title="Editar"><i
                      class="fa fa-edit"></i>
                  </a>
                  <a (click)="setIDEmpresaAtivarInativar(empresa._id, empresa.situacao)" class="btn btn-warning"
                    title="Ativar/Inativar" data-toggle="modal" data-target="#confirmaAtivacao"><i
                      class="fa fa-handshake-o"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="table" *ngIf="pager.total === 0">
            <thead>
              <tr>
                <th class="text-center">Nenhum registro encontrado!</th>
              </tr>
            </thead>
          </table>

          <div class="text-center" *ngIf="pager.total > pager.perPage">
            <paginator class="pagination" [pager]="pager" (onPager)="loadPage($event)"></paginator>
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="modal inmodal" id="confirmaAtivacao" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content animated bounceInRight">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
              class="sr-only">Close</span></button>
          <small class="font-bold">Ativar/Inativar Empresa.</small>
        </div>
        <div class="modal-body">
          <div align="center">
            <p>
              Tem certeza que deseja ATIVAR/INATIVAR Empresa?
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" (click)="ativarInativarEmpresa()"
            data-dismiss="modal">Ativar/Inativar</button>
          <button type="button" class="btn btn-white" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>