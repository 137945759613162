import { Component } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AlertService } from './alert.service';

@Component({
  selector: 'alert',
  template: ''
})
export class AlertComponent {

  message: any;
  private currentTimeout: any;

  optionsToastr: IndividualConfig = {
    positionClass: 'toast-top-right',
    progressBar: true,
    timeOut: 7000,
  } as IndividualConfig;

  constructor(private alertService: AlertService, private toastr: ToastrService) { }

  ngOnInit() {
    this.alertService.getMessage().subscribe(
      message => {
        if (message) {
          if (message.type === 'info') {
            this.toastr.info(message.text, 'Sucesso', this.optionsToastr);
          } else if (message.type === 'success') {
            this.toastr.success(message.text, 'Sucesso', this.optionsToastr);
          } else if (message.type === 'error') {
            this.toastr.error(message.text, 'Ops!', this.optionsToastr);
          } else if (message.type === 'warning') {
            this.toastr.warning(message.text, 'Atenção!', this.optionsToastr);
            // } else {
            // this.toastr.show(message.text, 'Mensagem', this.optionsToastr);
          }
        }
      });
  }
}
