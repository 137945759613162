import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Atendimento } from '../models/atendimento.model';
import { Franquia } from '../models/franquia.model';

@Injectable({ providedIn: 'root' })
export class AtendimentoService extends AbstractService<Atendimento> {

  getURLBase(): string {
    return '/atendimentos/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: any): void {
    delete obj._id;
  }
  preUpdate(obj: any): void {
  }

  getAtendimentosPorEmpresa(idEmpresa: string, data: Date): Observable<Atendimento[] | any> {
    return super.get('empresa/' + idEmpresa + '/' + data.getTime());
  }

  getAtendimentosPorFranquia(idFranquia: string, data: Date): Observable<Atendimento[] | any> {
    return super.get('franquia/' + idFranquia + '/' + data.getTime());
  }

  getCountPorDataEmpresaUltimos7dias(idEmpresa: string, data: Date): Observable<any> {
    return super.get('count/empresa/ultimos7dias/' + idEmpresa + '/' + data.getTime());
  }

  getCountPorDataFranquiaUltimos7dias(idFranquia: string, data: Date): Observable<any> {
    return super.get('count/franquia/ultimos7dias/' + idFranquia + '/' + data.getTime());
  }

  concluirAtendimento(obj: Atendimento) {
    return super.put('concluirAtendimento', obj);
  }

}
