import { Component } from '@angular/core';

@Component({
  selector: 'lib-lib-smart-core',
  template: `
    <p>
      lib-smart-core works!
    </p>
  `,
  styles: [
  ]
})
export class LibSmartCoreComponent {

}
