import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Franquia } from "../models/franquia.model";
import { Localizacao } from '../models/localizacao.model';
import { Mesa } from "../models/mesa.model";

@Injectable({ providedIn: 'root' })
export class ConstantService {

  private subjectFranquia = new Subject<any>();
  private subjectMesa = new Subject<any>();
  private subjectLocalizacao = new Subject<any>();

  setFranquiaSession(franquia: Franquia) {
    this.subjectFranquia.next(franquia);
  }

  setMesaSession(mesa: Mesa) {
    this.subjectMesa.next(mesa);
  }

  setLocalizacaoSession(localizacao: Localizacao) {
    this.subjectLocalizacao.next(localizacao);
  }

  getFranquiaSession() {
    return this.subjectFranquia.asObservable();
  }

  getMesaSession() {
    return this.subjectMesa.asObservable();
  }

  getLocalizacaoSession() {
    return this.subjectLocalizacao.asObservable();
  }

}