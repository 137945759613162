import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { TipoAtendimento } from '../models/tipoAtendimento.model';

@Injectable({ providedIn: 'root' })
export class TipoAtendimentoService extends AbstractService<TipoAtendimento>  {

  getURLBase(): string {
    return '/tipo-atendimento/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: TipoAtendimento): void {
    delete obj._id;
  }
  preUpdate(obj: TipoAtendimento): void {
  }

  getTipoAtendimentoMaisUsados(idFila: string) {
    return super.get('mais/usados/' + idFila);
  }

}