import { ReplaySubject } from "rxjs";
import { NgZone, EventEmitter } from "@angular/core";

function win() {
  return window;
}
/**
 * @return {?}
 */
function YT() {
  return win()['YT'];
}
/**
 * @return {?}
 */
function Player() {
  return YT().Player;
}
const defaultSizes = {
  height: 270,
  width: 367
};

export class YoutubeHelper {

  htmlId: string = 'player';
  zone: NgZone;

  api: ReplaySubject<any>;
  ytApiLoaded: Boolean;
  protocol: string;

  player: any;
  playerVars: any;
  ready: EventEmitter<any>;
  change: EventEmitter<any>;

  constructor(private _ngZone: NgZone) {
    this.zone = _ngZone;
    this.protocol = this.getProtocol();

    this.playerVars = {};
    this.ready = new EventEmitter(); // player created and initialized - sends instance of the player
    this.change = new EventEmitter(); // state change: send the YT event with its state

    this.createApi();
    this.loadPlayerApi({
      protocol: this.protocol
    });
  }

  createApi() {
    this.api = new ReplaySubject(1);
    const onYouTubeIframeAPIReady = () => {
      if (win()) {
        this.api.next(YT());
      }
    };
    win()['onYouTubeIframeAPIReady'] = onYouTubeIframeAPIReady;
  }

  loadPlayerApi(options) {
    const doc = win().document;
    if (!this.ytApiLoaded) {
      this.ytApiLoaded = true;
      const playerApiScript = doc.createElement("script");
      playerApiScript.type = "text/javascript";
      playerApiScript.src = `${options.protocol}://www.youtube.com/iframe_api`;
      doc.body.appendChild(playerApiScript);
    }
  }

  setupPlayer(outputs, sizes, videoId = '', playerVars) {
    const /** @type {?} */ createPlayer = () => {
      if (Player) {
        this.createPlayer(this.htmlId, outputs, sizes, videoId, playerVars);
      }
    };
    this.api.subscribe(createPlayer);
  }

  /**
   * @param {?} elementId
   * @param {?} outputs
   * @param {?} sizes
   * @param {?=} videoId
   * @param {?=} playerVars
   * @return {?}
   */
  createPlayer(elementId, outputs, sizes, videoId = '', playerVars = {}) {
    const playerSize = {
      height: sizes.height || defaultSizes.height,
      width: sizes.width || defaultSizes.width
    };
    const ytPlayer = Player();
    this.player = new ytPlayer(elementId, Object.assign({}, playerSize, {
      events: {
        onReady: (ev) => {
          this.zone.run(() => outputs.ready && outputs.ready.next(ev));
        },
        onStateChange: (ev) => {
          this.zone.run(() => outputs.change && outputs.change.next(ev));
        }
      }, playerVars,
      videoId
    }));
    return this.player;
  }

  playVideoById(id) {
    if (this.player) {
      this.player.loadVideoById(id);
    }
  }

  cuePlaylist(listType: string, list: string | string[]) {
    if (this.player) {
      this.player.cuePlaylist(listType, list);
    }
  }

  /**
   * Define o volume. Aceita um número inteiro entre 0 e 100.
   * @param volume 
   */
  setVolume(volume: number) {
    if (this.player) {
      this.player.setVolume(volume)
    }
  }

  /**
   * @return {?}
   */
  getProtocol() {
    const hasWindow = window && window.location;
    const protocol = hasWindow ? window.location.protocol.replace(':', '') : 'http';
    return protocol;
  }

  reframe() {
    var iframe = document.getElementById("player");
    var frameStyle = iframe.style;
    frameStyle.position = 'absolute';
    frameStyle.top = '0';
    frameStyle.bottom = '0';
    frameStyle.left = '0';
    frameStyle.width = '100%';
    frameStyle.height = '100%';
    frameStyle.border = '0';
  }

  reframeOld(target) {
    var frames = typeof target === 'string' ? document.querySelectorAll(target) : target;
    var c = 'js-reframe embed-responsive-item';
    if (!('length' in frames)) frames = [frames];
    for (var i = 0; i < frames.length; i += 1) {
      var frame = frames[i];
      var hasClass = frame.className.split(' ').indexOf(c) !== -1;
      if (hasClass) continue;
      var hAttr = frame.getAttribute('height');
      var wAttr = frame.getAttribute('width');
      if (wAttr.indexOf('%') > -1 || frame.style.width.indexOf('%') > -1) continue;
      var h = hAttr || frame.offsetHeight;
      var w = wAttr || frame.offsetWidth;
      var padding = h / w * 100;
      var div = document.createElement('div');
      div.className = c;
      // var divStyles = div.style;
      // divStyles.position = 'relative';
      // divStyles.width = '100%';
      // divStyles.paddingTop = padding + "%";
      var frameStyle = frame.style;
      frameStyle.position = 'absolute';
      frameStyle.width = '100%';
      frameStyle.height = '100%';
      frameStyle.left = '0';
      frameStyle.top = '0';
      frame.parentNode.insertBefore(div, frame);
      frame.parentNode.removeChild(frame);
      div.appendChild(frame);
    }
  }


}