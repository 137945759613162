
export class TVHelper {

  public YT: any;
  public video: any;
  public player: any;
  public reframed: Boolean = false;

  public video_live_id: string;
  public playlist_id: string;

  url: string;
  urlFinal: string;

  constructor() {
    this.init();
  }

  init() {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  set(url: string) {
    this.url = url;
    return this;
  }

  setupURLPlaylist() {
    var listid = this.url.match("list=([a-zA-Z0-9\-\_]+)&?");
    if (listid) {
      // list = list ? list[1] : "";
      console.log('playlist id = ' + listid[1]);
      this.playlist_id = listid[1];
      this.urlFinal = 'https://www.youtube.com/embed/videoseries?list=' + listid[1] + '&loop=1&autoplay=1';
      this.playPlaylist();
    } else {
      var videoid = this.url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
      if (videoid != null) {
        this.video_live_id = videoid[1];
        console.log("video id = ", videoid[1]);
        this.urlFinal = 'https://www.youtube.com/embed/' + this.video_live_id + '?loop=1&autoplay=1';
        this.playPlaylistAsVideo();
      } else {
        console.log("The youtube url is not valid.");
      }
    }

  }

  playPlaylist() {
    window['onYouTubeIframeAPIReady'] = (e) => {
      this.YT = window['YT'];
      this.reframed = false;
      this.player = new window['YT'].Player('player', {
        // videoId: this.video_live_id,
        // playerVars: { 'autoplay': 0, 'controls': 1, 'playlist': ['your_video_id', '...'] },
        playerVars: {
          'loop': 1, 'autoplay': 1, 'controls': 1, 'listType': 'playlist', 'list': this.playlist_id, enablejsapi: 1
        },
        events: {
          'onStateChange': this.onPlayerStateChange.bind(this),
          'onError': this.onPlayerError.bind(this),
          'onReady': (e) => {
            if (!this.reframed) {
              this.reframed = true;
              // /this.reframe(e.target.a);
            }
          }
        }
      });
    };
  }

  playPlaylistAsVideo() {
    console.log('playPlaylistAsVideo');
    window['onYouTubeIframeAPIReady'] = (e) => {
      this.YT = window['YT'];
      this.reframed = false;
      this.player = new window['YT'].Player('player', {
        videoId: this.video_live_id,
        // playerVars: { 'autoplay': 0, 'controls': 1, 'playlist': ['your_video_id', '...'] },
        playerVars: {
          loop: 1, autoplay: 1, controls: 1, playlist: this.video_live_id, enablejsapi: 1
        },
        events: {
          'onStateChange': this.onPlayerStateChange.bind(this),
          'onError': this.onPlayerError.bind(this),
          'onReady': (e) => {
            if (!this.reframed) {
              this.reframed = true;
              // /this.reframe(e.target.a);
            }
          }
        }
      });
    };
  }

  setupURLVideoLive() {
    console.log('setupURLVideoLive');
    // link for embed youtube live
    var videoid = this.url.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
    if (videoid != null) {
      this.video_live_id = videoid[1];
      console.log("video id = ", videoid[1]);
      this.urlFinal = 'https://www.youtube.com/embed/' + this.video_live_id + '?loop=1&autoplay=1';
      // this.playVideoLive();
      this.player.loadVideoById(this.video_live_id);
    } else {
      console.log("The youtube url is not valid.");
    }
  }

  onPlayerStateChange(event) {
    console.log(event)
    switch (event.data) {
      case window['YT'].PlayerState.PLAYING:
        if (this.cleanTime() == 0) {
          console.log('started ' + this.cleanTime());
        } else {
          console.log('playing ' + this.cleanTime())
        };
        break;
      case window['YT'].PlayerState.PAUSED:
        if (this.player.getDuration() - this.player.getCurrentTime() != 0) {
          console.log('paused' + ' @ ' + this.cleanTime());
        };
        break;
      case window['YT'].PlayerState.ENDED:
        console.log('ended ');
        break;
    };
  };

  //utility
  cleanTime() {
    return Math.round(this.player.getCurrentTime())
  };
  onPlayerError(event) {
    console.log('onPlayerError')
    console.log(event)
    switch (event.data) {
      case 2:
        console.log('' + this.video)
        break;
      case 100:
        break;
      case 101 || 150:
        break;
    };
  };

}