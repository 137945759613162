import { Inject, Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DomSanitizer } from '@angular/platform-browser';
import { finalize } from 'rxjs';
import { ENV, Environment } from '../../../commons/injection.token';
import { StorageInterface } from '../storage.interface';
import { UploadTypeEnum } from '../upload.type.enum';

@Injectable({ providedIn: 'root' })
export class FirebaseService implements StorageInterface {

  constructor(
    @Inject(ENV) private env: Environment,
    private _sanitizer: DomSanitizer,
    private storage: AngularFireStorage,
  ) { }

  /**
   * 
   * https://www.bezkoder.com/angular-16-firebase-storage/
   * 
   * @param file 
   * @param filename 
   * @param uploadTypeEnum 
   * @returns 
   */
  uploadFile(file: File, filename: string, uploadTypeEnum: UploadTypeEnum): Promise<any> {
    try {
      const filePath = uploadTypeEnum.toString() + filename;
      const storageRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, file);

      return new Promise<any>((resolve, reject) => {
        return uploadTask.snapshotChanges().pipe(
          finalize(() => {
            return storageRef.getDownloadURL().subscribe((url) => {
              console.log('url', url)
              resolve({
                url: url,
                urlSafe: this._sanitizer.bypassSecurityTrustResourceUrl(url)
              });
            });
          })
        ).subscribe({
          error: (err) => {
            console.log('Ocorreu algum erro ao enviar o arquivo para o Firebase Google')
            console.log(JSON.stringify(err));
            reject(err);
          }
        })
        // return uploadTask.percentageChanges();
      });
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  /**
   * 
   * https://www.bezkoder.com/angular-16-firebase-storage/
   * 
   * @param storageUrl 
   */
  deleteFile(storageUrl: string) {
    return new Promise<any>((resolve, reject) => {
      try {
        if (storageUrl.indexOf(this.env['firebase']['storageBucket']) !== -1) {
          const deleteTask = this.storage.refFromURL(storageUrl);
          resolve(deleteTask.delete());
        } else {
          resolve(storageUrl);
        }
      } catch (error) {
        console.log(error);
        resolve(error);
      }
    });
  }

}

