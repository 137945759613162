import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../../commons/abstract.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RelatorioTipoAtendimentoService extends AbstractService<any> {

  getURLBase(): string {
    return '/reports/relatorio-tipo-atendimento/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: any): void {
    delete obj._id;
  }
  preUpdate(obj: any): void {
  }

}