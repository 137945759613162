/**
 * abstract.services.ts
 */
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchParams } from './abstract.types';
import { Pager } from './pager';


export abstract class AbstractService<T> {

  private sHttp: HttpClient;

  constructor(http: HttpClient) {
    this.sHttp = http;
  }

  // must be implemented in derived classes
  abstract getURLBase(): string;

  get(path: string): Observable<any> {
    return this.sHttp.get(this.getURLBase() + path);
  }

  post(path: string, obj: any, config = {}): Observable<any> {
    return this.sHttp.post(this.getURLBase() + path, obj, config);
  }

  put(path: string, obj: any): Observable<any> {
    return this.sHttp.put(this.getURLBase() + path, obj);
  }

  getAllPager(page: number, perPage: number): Observable<Pager<T>> {
    return this.sHttp.get<Pager<T>>(this.getURLBase() + 'pager/' + page + '/' + perPage);
  }

  getAllPagerSearch(page: number, perPage: number, search: string): Observable<Pager<T>> {
    return this.sHttp.get<Pager<T>>(this.getURLBase() + 'pager/search/' + page + '/' + perPage + '/' + search);
  }

  getAllPagerQuerySearch(page: number, perPage: number, search?: string, params?: Partial<SearchParams>): Observable<Pager<T>> {
    const baseURL = `${this.getURLBase()}pager/query/search`;
    const pagering = `${baseURL}?page=${page}&perPage=${perPage}`;
    const searching = `${pagering}${!!search ? '&search=' + search : ''}`;
    let urling = `${searching}`;
    if (!!params) {
      for (let param in params) {
        urling = `${urling}&${param}=${params[param]}`;
      }
    }
    return this.sHttp.get<Pager<T>>(urling);
  }

  getAll(): Observable<T[]> {
    return this.sHttp.get<T[]>(this.getURLBase() + 'list');
  }

  count(): Observable<number> {
    return this.sHttp.get<number>(this.getURLBase() + 'count');
  }

  getById(id: string): Observable<T> {
    return this.sHttp.get<T>(this.getURLBase() + id);
  }

  abstract preCreate(obj: T): void;
  create(obj: T): Observable<T> {
    this.preCreate(obj);
    return this.sHttp.post<T>(this.getURLBase() + 'create', obj);
  }

  abstract preUpdate(obj: T): void;
  update(obj: T): Observable<T> {
    this.preUpdate(obj);
    return this.sHttp.put<T>(this.getURLBase() + 'update', obj);
  }

  delete(id: string): Observable<void> {
    return this.sHttp.delete<void>(this.getURLBase() + 'delete/' + id);
  }

}