import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Mesa } from "../models/mesa.model";

@Injectable({ providedIn: 'root' })
export class MesaService extends AbstractService<Mesa> {

  getURLBase(): string {
    return '/mesas/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Mesa): void {
    delete obj._id;
  }
  preUpdate(obj: Mesa): void {
  }

  getMesasPorFranquia(idFranquia: string): Observable<Mesa[] | any> {
    return super.get('franquia/' + idFranquia);
  }

}