<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Usuários</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/empresa']">Dashboard</a>
      </li>
      <li>
        <a [routerLink]="['/usuario']">Usuários</a>
      </li>
      <li class="active">
        <strong>Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #usuarioForm="ngForm" novalidate>

            <div class="form-group">
              <label>Senha</label>
              <input type="password" placeholder="" class="form-control" name="senha" id="senha" #senha="ngModel"
                [(ngModel)]="usuario.senha" required>
            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!usuarioForm.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>