import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Usuario } from '../models/usuario.model';

@Injectable({ providedIn: 'root' })
export class UsuarioService extends AbstractService<Usuario>  {

  getURLBase(): string {
    return '/usuarios/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }
  preCreate(obj: Usuario): void {
    delete obj._id;
  }
  preUpdate(obj: Usuario): void {
  }

  getUsuariosPorEmpresa(idEmpresa: string): Observable<Usuario[] | any> {
    return this.http.get(this.getURLBase() + 'empresa/' + idEmpresa);
  }

  getUsuariosPorGrupo(idGrupo: string): Observable<Usuario[] | any> {
    return this.http.get(this.getURLBase() + 'grupo/' + idGrupo);
  }

  updateSenha(id: string, obj: Usuario): Observable<Usuario | any> {
    return this.http.put(this.getURLBase() + 'updateSenha/' + id, obj);
  }

  uploadImagemUsuario(id: string, formData: FormData) {
    return this.http.post(this.getURLBase() + 'update/image/' + id, formData);
  }

  deleteImagemUsuario(id: string) {
    return this.http.delete(this.getURLBase() + 'delete/image/' + id)
  }

  bloquear(id: string) {
    return this.http.get(this.getURLBase() + 'bloquear/' + id);
  }

  desbloquear(id: string) {
    return this.http.get(this.getURLBase() + 'desbloquear/' + id);
  }

  createMany(usuarios: Usuario[]) {
    return this.http.post(this.getURLBase() + 'create/many', usuarios);
  }

  pagerUsuariosPorEmpresa(page: number, perPage: number, idEmpresa: string): Observable<Usuario[] | any> {
    return this.http.get(this.getURLBase() + 'pager/' + page + '/' + perPage + '/empresa/' + idEmpresa);
  }

  updateSenhaMain(id: string, obj: { senha: string, novaSenha: string, confirmaNovaSenha: string }) {
    return super.post('update/senha/' + id, obj);
  }

  esqueciMinhaSenha(email: string) {
    return this.http.get('/public/usuario/enviar/email/esqueci/senha/' + email);
  }

  getPublicUsuarioPorId(id: string) {
    return this.http.get('/public/usuario/get/' + id);
  }

  confirmarAlterarSenha(obj: { id: string, novaSenha: string, confirmarNovaSenha: string }) {
    return this.http.post('/public/usuario/confirmar/alterar/esqueci/senha/', obj);
  }

  getUserAPI() {
    return super.get('get/user/api');
  }

}