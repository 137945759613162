export class ColorUtil {

    // Função para ajustar o alpha de uma cor em hexadecimal
    static adjustAlpha(hexColor: string, alpha: number): string {
      // Remover o "#" se estiver presente
      hexColor = hexColor.replace("#", "");
  
      // Converter a cor para RGB
      const r = parseInt(hexColor.substring(0, 2), 16);
      const g = parseInt(hexColor.substring(2, 4), 16);
      const b = parseInt(hexColor.substring(4, 6), 16);
  
      // Validar o valor de alpha (entre 0 e 1)
      alpha = Math.min(1, Math.max(0, alpha));
  
      // Montar a nova cor com alpha no formato RGBA
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    }
}