import { Component } from '@angular/core';
import { AtendimentoService, EmpresaService, FranquiaService, SenhaService, UsuarioService } from 'lib-smart-core';

@Component({
  selector: 'dashboard-component',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent {

  model: any;

  constructor(
    private empresaService: EmpresaService,
    private usuarioService: UsuarioService,
    private franquiaService: FranquiaService,
    private senhaService: SenhaService,
    private atendimentoService: AtendimentoService,
  ) {
    this.model = {
      COUNT_EMPRESAS: 0,
      COUNT_FRANQUIAS: 0,
      COUNT_USUARIOS: 0,
      COUNT_SENHAS: 0,
      COUNT_ATENDIMENTOS: 0,
    };
    this.countEmpresas();
    this.countFranquias();
    this.countUsuarios();
    this.countSenhas();
    this.countAtendimentos();
  }

  countEmpresas() {
    this.empresaService.count().subscribe(count => this.model.COUNT_EMPRESAS = count);
  }
  countFranquias() {
    this.franquiaService.count().subscribe(count => this.model.COUNT_FRANQUIAS = count);
  }
  countUsuarios() {
    this.usuarioService.count().subscribe(count => this.model.COUNT_USUARIOS = count);
  }
  countSenhas() {
    this.senhaService.count().subscribe(count => this.model.COUNT_SENHAS = count);
  }
  countAtendimentos() {
    this.atendimentoService.count().subscribe(count => this.model.COUNT_ATENDIMENTOS = count);
  }
}