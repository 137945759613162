import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RelatorioComponent } from "./relatorio.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule
  ],
  declarations: [
    RelatorioComponent
  ],
  exports: [
    RelatorioComponent
  ],
  providers: []
})
export class RelatorioModule { }