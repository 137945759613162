<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Empresas</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{model?.COUNT_EMPRESAS}}</h1>
          <div class="stat-percent font-bold text-success">98% <i class="fa fa-bolt"></i></div>
          <small>Total</small>
        </div>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Estabelecimentos</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{model?.COUNT_FRANQUIAS}}</h1>
          <div class="stat-percent font-bold text-info">20% <i class="fa fa-level-up"></i></div>
          <small>Total</small>
        </div>
      </div>
    </div>
    <div class="col-lg-2">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Usuários</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{model?.COUNT_USUARIOS}}</h1>
          <div class="stat-percent font-bold text-navy">44% <i class="fa fa-level-up"></i></div>
          <small>Total</small>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Senhas</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{model?.COUNT_SENHAS}}</h1>
          <div class="stat-percent font-bold text-danger">38% <i class="fa fa-level-down"></i></div>
          <small>Total</small>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Atendimentos</h5>
        </div>
        <div class="ibox-content">
          <h1 class="no-margins">{{model?.COUNT_ATENDIMENTOS}}</h1>
          <div class="stat-percent font-bold text-danger">38% <i class="fa fa-level-down"></i></div>
          <small>Total</small>
        </div>
      </div>
    </div>
  </div>
  <empresa-lista-component></empresa-lista-component>
</div>