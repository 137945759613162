<div class="middle-box text-center loginscreen animated fadeInDown">
  <div>
    <div>

      <h1>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 397.63 211.72">
          <defs>
            <style>
              .f03f0974-e966-498e-84e2-96991bcc8b12 {
                fill: #3179ce;
              }
            </style>
          </defs>
          <title>smartline-vert</title>
          <g id="326c75f0-e720-4ab0-bdde-5ac839da7d98" data-name="Layer 2">
            <g id="82d27797-e44e-49da-b24c-05568a9efe21" data-name="Layer 3">
              <path
                d="M13.78,193.58q.44,3.57,1.87,5.09,2.53,2.69,9.39,2.68a14.45,14.45,0,0,0,6.4-1.18,3.92,3.92,0,0,0,.46-7q-1.91-1.19-14.22-4Q8.82,187,5.2,183.73t-3.63-9.24a15.91,15.91,0,0,1,5.67-12.23q5.65-5.12,15.94-5.12,9.75,0,15.89,3.85t7,13.26H32.53a7.94,7.94,0,0,0-1.49-4.1q-2.25-2.73-7.65-2.73c-3,0-5.08.45-6.34,1.37a3.91,3.91,0,0,0-1.88,3.2,3.5,3.5,0,0,0,2,3.35q2,1.08,14.19,3.72,8.13,1.89,12.18,5.7a12.89,12.89,0,0,1,4,9.66,15.53,15.53,0,0,1-5.76,12.47Q36,211.72,24,211.72q-12.28,0-18.14-5.11a16.55,16.55,0,0,1-5.85-13Z" />
              <path
                d="M117.54,158.79a15.19,15.19,0,0,1,6.06,4.62,14.7,14.7,0,0,1,3,6.6,44.84,44.84,0,0,1,.48,7.59L126.94,210H113V177.27a10.58,10.58,0,0,0-1-4.81c-1.2-2.39-3.43-3.58-6.67-3.58-3.75,0-6.35,1.54-7.77,4.62a14.2,14.2,0,0,0-1.1,5.89V210H82.82V179.39a17.17,17.17,0,0,0-.95-6.65Q80.15,169,75.14,169c-3.88,0-6.48,1.24-7.82,3.72a14.24,14.24,0,0,0-1.1,6.32V210H52.44V158.7H65.65v7.49a21.64,21.64,0,0,1,4.77-5.7,16.47,16.47,0,0,1,10.25-3,16.36,16.36,0,0,1,9.64,2.6,15.11,15.11,0,0,1,4.48,6.17,17.11,17.11,0,0,1,6.63-6.64,20.35,20.35,0,0,1,9.35-2.13A18.28,18.28,0,0,1,117.54,158.79Z" />
              <path
                d="M156.4,178.58a22.06,22.06,0,0,0,5.43-1.18c2-.82,2.93-2.09,2.93-3.82,0-2.1-.75-3.56-2.24-4.36a14.38,14.38,0,0,0-6.6-1.2c-3.26,0-5.56.79-6.92,2.36a9.68,9.68,0,0,0-1.93,4.71H134A19.5,19.5,0,0,1,137.79,164q5.42-6.79,18.58-6.79a33.46,33.46,0,0,1,15.23,3.34q6.66,3.36,6.66,12.64v23.56q0,2.44.09,5.94a7.37,7.37,0,0,0,.82,3.58,4.83,4.83,0,0,0,2,1.56v2H166.38a14.54,14.54,0,0,1-.85-2.93c-.16-.91-.29-1.94-.39-3.11a27.83,27.83,0,0,1-6.52,5.14,20,20,0,0,1-10,2.5,17.38,17.38,0,0,1-11.73-4q-4.62-4-4.62-11.29,0-9.48,7.44-13.72,4.1-2.31,12-3.3Zm8.31,6.27a15.58,15.58,0,0,1-2.63,1.29,22.2,22.2,0,0,1-3.65.92l-3.09.57a20.2,20.2,0,0,0-6.25,1.84,6.11,6.11,0,0,0-3.21,5.7q0,3.44,2,5a7.53,7.53,0,0,0,4.77,1.53,14.39,14.39,0,0,0,8.21-2.54q3.75-2.55,3.89-9.29Z" />
              <path
                d="M213,157.4l1.17.07v13.77c-.86-.1-1.62-.16-2.28-.19s-1.21-.05-1.63-.05q-8.21,0-11,5.28-1.57,3-1.58,9.14V210H183.93V158.61h13v8.95c2.1-3.42,3.92-5.77,5.48-7a15,15,0,0,1,9.92-3.16Z" />
              <path
                d="M217.9,168.64v-9.56h7.25V144.75h13.44v14.33H247v9.56h-8.45v27.15c0,2.11.27,3.42.81,3.94s2.2.77,5,.77c.42,0,.85,0,1.32,0l1.36-.07v10l-6.44.23c-6.43.22-10.81-.88-13.17-3.3-1.52-1.53-2.28-3.91-2.28-7.11V168.64Z" />
              <path class="f03f0974-e966-498e-84e2-96991bcc8b12" d="M264.76,210H251.17V140.51h13.59Z" />
              <path class="f03f0974-e966-498e-84e2-96991bcc8b12" d="M273.32,158.61h13.79V210H273.32Z" />
              <circle class="f03f0974-e966-498e-84e2-96991bcc8b12" cx="280.22" cy="148.77" r="6.37" />
              <path class="f03f0974-e966-498e-84e2-96991bcc8b12"
                d="M337.3,161.55q5.11,4.17,5.11,13.83V210H328.48V178.73a14.13,14.13,0,0,0-1.09-6.22c-1.32-2.64-3.85-4-7.57-4q-6.87,0-9.42,5.8a19.88,19.88,0,0,0-1.33,7.82V210H295.48V158.7h13.17v7.49a22.77,22.77,0,0,1,4.93-5.7,17.3,17.3,0,0,1,10.59-3.11Q332.19,157.38,337.3,161.55Z" />
              <path class="f03f0974-e966-498e-84e2-96991bcc8b12"
                d="M384,159.65a20.94,20.94,0,0,1,9,7.6,25,25,0,0,1,4.14,10.62,63.94,63.94,0,0,1,.45,10.19H359.75q.31,7.73,5.44,10.84a13.89,13.89,0,0,0,7.49,1.93,11.61,11.61,0,0,0,7.55-2.35,11.43,11.43,0,0,0,2.8-3.54H396.9q-.54,4.58-5,9.29-7,7.49-19.55,7.49A28.55,28.55,0,0,1,354,205.4q-7.94-6.32-7.94-20.57,0-13.35,7.16-20.47t18.59-7.12A29.83,29.83,0,0,1,384,159.65Zm-20.34,11.6a13.88,13.88,0,0,0-3.61,7.95H383.5c-.25-3.56-1.45-6.27-3.62-8.12a12,12,0,0,0-8.06-2.77Q366.59,168.31,363.7,171.25Z" />
              <path class="f03f0974-e966-498e-84e2-96991bcc8b12"
                d="M267.23,59.25,233,0H164.61L130.4,59.25l34.21,59.24H233Zm-119.6,0,25.59-44.33h24.69L176.82,54.83a7.72,7.72,0,0,0-.51.94v0h0a8.15,8.15,0,0,0-.76,3.46h0a8.32,8.32,0,0,0,8.3,8.29h15.37l-18.89,36h-7.1Zm52.09,44.33L220.8,63.66a6.61,6.61,0,0,0,.52-.94h0a8.37,8.37,0,0,0,.76-3.46h0a8.32,8.32,0,0,0-8.3-8.3H198.42l19-36h6.95L250,59.25l-25.59,44.33Z" />
            </g>
          </g>
        </svg>
      </h1>

    </div>
    <h3>Smartline | Admin</h3>
    <p>Utilize seu email e senha para acessar o sistema.
      <!--Continually expanded and constantly improved Inspinia Admin Them (IN+)-->
    </p>
    <form class="m-t" role="form" name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
      <div class="form-group">
        <input type="text" class="form-control" placeholder="Email" id="email" name="email" [(ngModel)]="model.email"
          #email="ngModel" required>
      </div>
      <div class="form-group">
        <input type="password" class="form-control" placeholder="Senha" id="senha" name="senha"
          [(ngModel)]="model.senha" #senha="ngModel" required>
      </div>
      <button type="submit" [disabled]="loading" class="btn btn-primary block full-width m-b">Acessar</button>
      <img *ngIf="loading"
        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />

      <a href="#"><small>Esqueceu sua senha?</small></a>
    </form>
  </div>
</div>