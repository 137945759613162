import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../commons/abstract.service';
import { Fila } from '../../models/fila.model';

@Injectable({ providedIn: 'root' })
export class PublicFilaService extends AbstractService<Fila> {

  getURLBase(): string {
    return '/public/filas/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Fila): void {
    delete obj._id;
  }
  preUpdate(obj: Fila): void {
  }

  getFilasPorFranquia(idFranquia: string): Observable<Fila[] | any> {
    return this.http.get(this.getURLBase() + 'franquia/' + idFranquia);
  }

}
