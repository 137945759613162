import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase/firebase.service';
import { UploadTypeEnum } from './upload.type.enum';

@Injectable({ providedIn: 'root' })
export class StorageService {

  constructor(
    private storageServiceImpl: FirebaseService
  ) {
  }

  uploadImage(file: File, filename: string, uploadTypeEnum: UploadTypeEnum): Promise<any> {
    return this.storageServiceImpl.uploadFile(file, filename, uploadTypeEnum);
  }

  deleteImage(storageUrl: string) {
    return this.storageServiceImpl.deleteFile(storageUrl);
  }

}
