import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from 'lib-smart-core';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { EmpresaDetailComponent } from "./modules/empresa/empresa-detail.component";
import { EmpresaFormsComponent } from './modules/empresa/empresa-forms.component';
import { EmpresaComponent } from './modules/empresa/empresa.component';
import { FranquiaFormsComponent } from "./modules/franquia/franquia-forms.component";
import { LoginComponent } from './modules/login/login.component';
import { PagamentoComponent } from "./modules/pagamento/pagamento.component";
import { RelatorioComponent } from "./modules/relatorio/relatorio.component";
import { SenderFormsComponent } from './modules/sender/sender-forms.component';
import { SenderListaComponent } from './modules/sender/sender.component';
import { UsuarioFormsComponent } from './modules/usuario/usuario-forms.component';
import { UsuarioSenhaFormsComponent } from './modules/usuario/usuario-senha-forms.component';

const appRoutes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },

  // dashboard
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },

  // empresa
  { path: 'empresa', component: EmpresaComponent, canActivate: [AuthGuard] },
  { path: 'empresa/new', component: EmpresaFormsComponent, canActivate: [AuthGuard] },
  { path: 'empresa/edit/:id', component: EmpresaFormsComponent, canActivate: [AuthGuard] },
  { path: 'empresa/detail/:idEmpresa', component: EmpresaDetailComponent, canActivate: [AuthGuard] },

  // grupo
  // { path: 'grupo', component: GrupoListaComponent, canActivate: [AuthGuard] },
  // { path: 'grupo/add', component: GrupoFormsComponent, canActivate: [AuthGuard] },
  // { path: 'grupo/edit/:idGrupo', component: GrupoFormsComponent, canActivate: [AuthGuard] },
  // { path: 'grupo/detail/:idGrupo', component: GrupoComponent, canActivate: [AuthGuard] },

  // usuario
  { path: 'usuario/add/empresa/:idEmpresa', component: UsuarioFormsComponent, canActivate: [AuthGuard] },
  { path: 'usuario/add/grupo/:idGrupo', component: UsuarioFormsComponent, canActivate: [AuthGuard] },
  { path: 'usuario/edit/:id', component: UsuarioFormsComponent, canActivate: [AuthGuard] },
  { path: 'usuario/edit/senha/:id', component: UsuarioSenhaFormsComponent, canActivate: [AuthGuard] },

  // franquia
  { path: 'franquia/add/empresa/:idEmpresa', component: FranquiaFormsComponent, canActivate: [AuthGuard] },
  { path: 'franquia/edit/:id', component: FranquiaFormsComponent, canActivate: [AuthGuard] },

  // sender
  { path: 'sender', component: SenderListaComponent, canActivate: [AuthGuard] },
  { path: 'sender/add', component: SenderFormsComponent, canActivate: [AuthGuard] },
  { path: 'sender/edit/:id', component: SenderFormsComponent, canActivate: [AuthGuard] },

  // pagamento
  { path: 'pagamento', component: PagamentoComponent, canActivate: [AuthGuard] },

  // relatorio
  { path: 'relatorio', component: RelatorioComponent, canActivate: [AuthGuard] }

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ]
})

export class AppRoutingModule {

}
