import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Sender, SenderService } from 'lib-smart-core';

@Component({
  templateUrl: './sender-forms.component.html'
})
export class SenderFormsComponent extends AbstractComponent implements OnInit {

  sender: Sender;
  isNew = true;

  metadataStr: string;

  constructor(
    private senderService: SenderService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.sender = {} as Sender;
    const idEmpresa: string = super.getParam('idEmpresa');
    if (idEmpresa) {
      this.sender = {} as Sender;
    } else {
      const id: string = super.getParam('id');
      if (id) {
        this.isNew = false;
        this.getSenderById(id.toString());
      }
    }
  }

  getSenderById(id: string) {
    return this.senderService.getById(id).subscribe(
      (sender) => {
        this.sender = sender;
        this.metadataStr = JSON.stringify(this.sender.metadata);
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  onSubmit(): void {
    console.log('on submit add franquia >> ' + JSON.stringify(this.sender));

    this.sender.metadata = JSON.parse(this.metadataStr);
    if (this.isNew) {
      this.senderService.create(this.sender)
        .subscribe(
          franquia => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    } else {
      this.senderService.update(this.sender)
        .subscribe(
          franquia => {
            this.alertService.success('Operação realizada com sucesso');
            super.goBack();
          },
          err => {
            this.alertService.error(err.error.message);
          }
        );
    }

  }
}
