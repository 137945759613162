import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { ENV, Environment } from '../../../commons/injection.token';

@Component({
  selector: 'map-component',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'],
})
export class MapComponent implements OnInit, OnDestroy {
  @Input('type') type: 'PIN' | 'VIEW' = 'PIN';
  @Input('latitude') latitude: number;
  @Input('longitude') longitude: number;
  @Input('draggable') draggable: boolean = false;
  @Input('makerColor') makerColor: string = '#3179CE';

  @Output('endedLngLat') endedLngLat = new EventEmitter();

  map: mapboxgl.Map;
  style = 'mapbox://styles/mapbox/streets-v10';

  markers: Array<mapboxgl.Marker>;

  environment: Environment;

  constructor(injector: Injector) {
    this.environment = injector.get(ENV);
  }

  ngOnInit() {
    this.markers = new Array<mapboxgl.Marker>();
    this.starting();
  }

  ngOnDestroy(): void {
    this.markers = new Array<mapboxgl.Marker>();
  }

  private starting() {
    const lat = this.latitude || this.environment['mapbox']['latitude'];
    const lng = this.longitude || this.environment['mapbox']['longitude'];
    (mapboxgl as any).accessToken = this.environment['mapbox']['accessToken'];
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.style,
      zoom: 16,
      center: [lng, lat],
    });

    // Add map controls
    // this.map.addControl(new mapboxgl.NavigationControl());

    if (this.type === 'PIN') {
      this.typePIN(lat, lng);
    }
  }

  private typePIN(lat: number, lng: number) {
    const marker = new mapboxgl.Marker({
      draggable: this.draggable,
      color: this.makerColor,
    })
      .setLngLat([lng, lat])
      .addTo(this.map);

    this.markers.push(marker);

    const onDragEnd = () => {
      const lngLat = marker.getLngLat();
      if (this.endedLngLat) {
        // coordinates.innerHTML = `Longitude: ${lngLat.lng}<br />Latitude: ${lngLat.lat}`;
        this.endedLngLat.emit({ latitude: lngLat.lat, longitude: lngLat.lng });
      }
    };

    marker.on('dragend', onDragEnd);
  }

  public setLatLng(lat: number, lng: number) {
    this.markers.forEach((element) => {
      element.remove();
    });

    this.typePIN(lat, lng);

    this.map.flyTo({
      center: [lng, lat],
      essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    });
  }

}
