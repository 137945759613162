import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Pager } from '../commons/pager';
import { Feedback } from '../models/feedback.model';

@Injectable({ providedIn: 'root' })
export class FeedbackService extends AbstractService<Feedback>  {

  getURLBase(): string {
    return '/feedbacks/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Feedback): void {
    delete obj._id;
  }
  preUpdate(obj: Feedback): void {
  }

  getFeedbackPorQuestionarioSintetico(idQuestionario: string, idFranquia: string): Observable<any> {
    return super.get(`resultado/sintetico/${idQuestionario}/${idFranquia}/`);
  }

  getFeedbackPorQuestionarioAnalitico(idQuestionario: string, idFranquia: string): Observable<any> {
    return super.get(`resultado/analitico/${idQuestionario}/${idFranquia}/`);
  }

  getFeedbackGraficoPorQuestionario(idQuestionario: string, mes: number, ano: number): Observable<any> {
    return super.get(`resultado/grafico/${idQuestionario}/${mes}/${ano}`);
  }

  getFeedbackGraficoPorQuestionarioPorFranquia(idQuestionario: string, mes: number, ano: number, idFranquia: string): Observable<any> {
    return super.get(`resultado/grafico/${idQuestionario}/${mes}/${ano}/${idFranquia}`);
  }

  getFeedbacksPorQuestionarioPager(idQuestionario: string, page: number, perPage: number): Observable<Pager<Feedback>> {
    return super.get(`pager/${page}/${perPage}/questionario/${idQuestionario}`);
  }

  getFeedbacksPorQuestionarioPagerPorFranquia(idQuestionario: string, page: number, perPage: number, mes: number, ano: number): Observable<Pager<Feedback>> {
    return super.get(`pager/${page}/${perPage}/questionario/${idQuestionario}/franquia/${mes}/${ano}`);
  }

  getFeedbacksPorQuestionarioPagerPorFranquia2(idQuestionario: string, page: number, perPage: number, mes: number, ano: number, idFranquia: string): Observable<Pager<Feedback>> {
    return super.get(`pager/${page}/${perPage}/questionario/${idQuestionario}/franquia/${mes}/${ano}/${idFranquia}`);
  }

}
