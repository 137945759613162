import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../commons/abstract.service';
import { Empresa } from '../../models/empresa.model';

@Injectable({ providedIn: 'root' })
export class PublicEmpresaService extends AbstractService<Empresa>  {

  getURLBase(): string {
    return '/public/empresas/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Empresa): void {
    delete obj._id;
  }
  preUpdate(obj: Empresa): void {
  }

  getCountFranquias(idEmpresa: string): Observable<number | any> {
    return this.http.get('/franquias/count/empresa/' + idEmpresa);
  }

  getCountUsuarios(idEmpresa: string): Observable<number | any> {
    return this.http.get('/usuarios/count/empresa/' + idEmpresa);
  }

  getCountMesas(idEmpresa: string): Observable<number | any> {
    return this.http.get('/mesas/count/empresa/' + idEmpresa);
  }

  getCountAtendimentos(idEmpresa: string): Observable<number | any> {
    return this.http.get('/atendimentos/count/empresa/' + idEmpresa);
  }

  getPublic(idEmpresa: string): Observable<number | any> {
    return this.http.get('/public/empresas/' + idEmpresa);
  }

  uploadImagemEmpresa(id: string, formData: FormData) {
    return this.http.post(this.getURLBase() + 'update/image/' + id, formData);
  }

  deleteImagemEmpresa(id: string) {
    return this.http.delete(this.getURLBase() + 'delete/image/' + id);
  }

}