import { Component, EventEmitter, Injector, Input, OnInit, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AbstractComponent } from '../../../commons/abstract.component';
import { Franquia } from '../../../models/franquia.model';
import { FranquiaService } from '../../../services/franquia.service';

@Component({
  selector: 'vincular-franquia',
  templateUrl: 'vincular-franquia.component.html'
})

export class VincularFranquiaComponent extends AbstractComponent implements OnInit {

  @Input() idEmpresa: string;

  @Output() onGetFranquiasSelecionadas: EventEmitter<any> = new EventEmitter();

  searchBox: string;
  model: any;
  franquiasModal: Franquia[];
  franquiasSelecionadas: Franquia[];
  usuariosObs: Observable<Franquia[]>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private franquiaService: FranquiaService,
    public injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getFranquiasPorEmpresa(this.idEmpresa);
  }

  search(termo: string) {
    this.termoDaBusca.next(termo);
  }

  loadModalFranquias() {
    this.searchBox = '';
    this.model.searchBox = '';
    this.termoDaBusca.next('');
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      (franquias) => {
        this.franquiasModal = franquias;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  excluirFranquiaSelecionada(index: number) {
    this.franquiasSelecionadas.splice(index, 1);
  }

  selecionarFraquia(franquia: Franquia) {
    this.franquiasSelecionadas.push(franquia);
  }

  franquiaJaAdicionada(id: string) {
    let result = false;
    for (let i = 0; i < this.franquiasSelecionadas.length; i++) {
      let franquia: Franquia = this.franquiasSelecionadas[i];
      if (franquia._id.toString() === id.toString()) {
        result = true;
        break;
      }
    };
    return result;
  }

  save() {
    this.onGetFranquiasSelecionadas.emit(this.franquiasSelecionadas);
  }
}
