import { Component, Input, OnInit } from '@angular/core';
import { Image } from '../../../models/imagem.model';
import { Empresa } from '../../../models/empresa.model';

@Component({
  selector: 'logo-image',
  template: `<img [src]="image" class="{{imgClass}}" style="{{imgStyles}}" alt="">`
})

export class LogoImageComponent implements OnInit {

  @Input() empresa: Empresa = {} as Empresa;
  @Input() idFranquia: string = '';
  @Input() imgClass?: string = '';
  @Input() imgStyles?: string = '';

  image: string = '';

  constructor() { }

  ngOnInit() {
    this.showImage();
  }

  showImage() {
    const { images, image } = this.empresa;

    if (images.length > 0) {
      this.image = this.getLogoEspecifica(images) !== null ? this.getLogoEspecifica(images) : image;
    } else {
      this.image = image;
    }
  }

  getLogoEspecifica(images: Image[]): string {

    let image: Image = null;

    images.forEach((img: Image) => {
      img['franquias'].forEach((id: string) => {
        if (id === this.idFranquia) {
          image = img;
        }
      })
    })
    return image !== null ? image.image : null;
  }

}
