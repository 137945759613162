<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Empresa</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li>
        Empresa
      </li>
      <li class="active">
        <strong>Adicionar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #empresaForm="ngForm" novalidate>
            <div class="form-group">
              <label>Nome<span class="text-danger">*</span></label>
              <input type="text" placeholder="Insira o Nome da Empresa" class="form-control" name="nome" id="nome"
                #nome="ngModel" [(ngModel)]="empresa.nome" required>
            </div>
            <div class="form-group">
              <label>Razão Social<span class="text-danger">*</span></label>
              <input type="text" placeholder="Insira a Razão Social da Empresa" class="form-control" name="razaoSocial"
                id="razaoSocial" #razaoSocial="ngModel" [(ngModel)]="empresa.razaoSocial" required>
            </div>
            <div class="form-group">
              <label>CNPJ<span class="text-danger">*</span></label>
              <input type="text" placeholder="Insira o CNPJ da Empresa"
                class="form-control" name="cpfCnpj" id="cpfCnpj" #cpfCnpj="ngModel" [(ngModel)]="empresa.cpfCnpj"
                mask="000.000.000-00||00.000.000/0000-00" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                required>
            </div>
            <div class="form-group">
              <label>Nome Responsável<span class="text-danger">*</span></label>
              <input type="text" placeholder="Insira o Nome do Responsável" class="form-control" name="nomeResponsavel"
                id="nomeResponsavel" #nomeResponsavel="ngModel" [(ngModel)]="empresa.nomeResponsavel" required>
            </div>
            <div class="form-group">
              <label>Email Responsável<span class="text-danger">*</span></label>
              <input type="text" placeholder="Insira o Email do Responsável" class="form-control"
                name="emailResponsavel" id="emailResponsavel" #emailResponsavel="ngModel"
                [(ngModel)]="empresa.emailResponsavel" required>
            </div>
            <div class="form-group">
              <label>Telefone</label>
              <input type="text" 
                placeholder="Insira o Telefone de Contato do Responsável" class="form-control"
                name="telefoneResponsavel" id="telefoneResponsavel" #telefoneResponsavel="ngModel"
                mask="(00) 0000-0000||(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                [(ngModel)]="empresa.telefoneResponsavel">
            </div>
            <div class="form-group">
              <label>Celular</label>
              <input type="text"
                placeholder="Insira o Telefone Celular de Contato do Responsável" class="form-control"
                name="celularResponsavel" id="celularResponsavel" #celularResponsavel="ngModel"
                mask="(00) 00000-0000" [showMaskTyped]="true" [dropSpecialCharacters]="false"
                [(ngModel)]="empresa.celularResponsavel">
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Cidade<span class="text-danger">*</span></label>
                  <input type="text" placeholder="Cidade" class="form-control" name="cidade" id="cidade" #nome="ngModel"
                    [(ngModel)]="empresa.cidade" required>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label>Estado<span class="text-danger">*</span></label>
                  <select name="estado" id="estado" [(ngModel)]="empresa.estado" class="form-control" required>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AP">Amapá</option>
                    <option value="AM">Amazonas</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PR">Paraná</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group" *ngIf="senders?.length > 0">
                  <label>Sender<span class="text-danger">*</span></label>
                  <select name="sender" id="sender" [(ngModel)]="empresa.sender" class="form-control" required>
                    <option *ngFor="let item of senders" value="{{item._id}}">{{item.name}}</option>
                  </select>
                </div>
              </div>

            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!empresaForm.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>