<div class="row wrapper border-bottom white-bg page-heading">
  <h2>Empresa</h2>
  <ol class="breadcrumb">
    <li>
      <a [routerLink]="['/dashboard']">Dashboard</a>
    </li>
    <li class="active">
      <strong>Empresa</strong>
    </li>
  </ol>
</div>

<empresa-lista-component></empresa-lista-component>