import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { FranquiaModule } from "../franquia/franquia.module";
import { PagamentoModule } from "../pagamento/pagamento.module";
import { UsuarioModule } from "../usuario/usuario.module";
import { EmpresaDetailComponent } from './empresa-detail.component';
import { EmpresaFormsComponent } from './empresa-forms.component';
import { EmpresaListaComponent } from './empresa-lista.component';
import { EmpresaComponent } from './empresa.component';

@NgModule({
  imports: [
    SharedModule,
    FranquiaModule,
    UsuarioModule,
    PagamentoModule,
  ],
  declarations: [
    EmpresaComponent,
    EmpresaFormsComponent,
    EmpresaListaComponent,
    EmpresaDetailComponent
  ],
  exports: [
    EmpresaComponent,
    EmpresaFormsComponent,
    EmpresaListaComponent,
    EmpresaDetailComponent,
  ],
  providers: []
})

export class EmpresaModule { }