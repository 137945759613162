import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractService } from '../commons/abstract.service';
import { Playlist } from '../models/playlist.model';

@Injectable({ providedIn: 'root' })
export class PlaylistService extends AbstractService<Playlist> {

  getURLBase(): string {
    return '/playlists/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Playlist): void {
    delete obj._id;
  }
  preUpdate(obj: Playlist): void {
  }

}