import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Sender, SenderService } from 'lib-smart-core';

@Component({
  selector: 'sender-lista',
  templateUrl: './sender.component.html'
})
export class SenderListaComponent extends AbstractComponent implements OnInit {

  senders: Sender[];
  senderSelecionado: Sender;

  constructor(
    private senderService: SenderService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getSenders();
  }

  getSenders() {
    return this.senderService.getAll().subscribe(
      (senders: Sender[]) => {
        this.senders = senders
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  selecionar(sender: Sender) {
    this.senderSelecionado = sender;
  }

  delete(): void {
    this.senderService.delete(this.senderSelecionado._id).subscribe(
      () => {
        this.getSenders();
        this.alertService.success('Operação realizada com sucesso');
      },
      err => {
        console.log(err);
        this.alertService.error(err.error.message);
      }
    );
  }

}