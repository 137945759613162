import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { SenderFormsComponent } from './sender-forms.component';
import { SenderListaComponent } from './sender.component';

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    SenderListaComponent,
    SenderFormsComponent
  ],
  exports: [
    SenderListaComponent,
    SenderFormsComponent
  ],
  providers: []
})
export class SenderModule { }