import { HttpClient, HttpHandler } from '@angular/common/http';
import { Injectable, Injector } from "@angular/core";
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService, CONSTANTES, Constantes, ENV, Environment } from '../../public-api';

@Injectable({ providedIn: 'root' })
export class CustomHttp extends HttpClient {

  private env: Environment;
  private constantes: Constantes;
  private alertService: AlertService;

  constructor(handler: HttpHandler, injector: Injector) {
    super(handler);
    this.env = injector.get(ENV);
    this.constantes = injector.get(CONSTANTES);
    this.alertService = injector.get(AlertService);
  }

  public override get(url: string, options): Observable<any> {
    return super.get(this.env['apiUrl'] + url, options).pipe(catchError(this.handleError));
  }

  public override post(url: string, body: string, options): Observable<any> {
    return super.post(this.env['apiUrl'] + url, body, options).pipe(catchError(this.handleError));
  }

  public override put(url: string, body: string, options): Observable<any> {
    return super.put(this.env['apiUrl'] + url, body, options).pipe(catchError(this.handleError));
  }

  public override delete(url: string, options): Observable<any> {
    return super.delete(this.env['apiUrl'] + url, options).pipe(catchError(this.handleError));
  }

  private handleError(error: any) {
    console.log('custom-http-client handleError')
    console.log(error)
    if (error.status === 401) {
      // 401 unauthorized response so log user out of client
      // localStorage.removeItem(this.constantes['currentUser']);
      window.location.href = '/login';
      this.alertService.error(error.message);
    }
    // return throwError(() => new Error(error));
    return observableThrowError(error);
  }
}

export function customHttpFactory(handler: HttpHandler, injector: Injector): HttpClient {
  return new CustomHttp(handler, injector);
}

export let customHttpProvider = {
  provide: HttpClient,
  useFactory: customHttpFactory,
  deps: [HttpHandler, Injector]
};
