/**
* data.util.ts
*/
import { addDays, addMonths, format, isAfter, isBefore, isEqual, parse, parseISO, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

export class DateUtil {

  /**
 * @param dateStr dd/MM/yyyy
 */
  static fromDateStr(dateStr: string): Date {
    let dateStrSplit = dateStr.split('/');
    let dateConverted = new Date(+dateStrSplit[2], +dateStrSplit[1] - 1, +dateStrSplit[0], 12, 0, 0);
    return dateConverted;
  }

  static toFormart(dateStr: string | Date, formatDate = 'dd/MM/yyyy'): string {
    if (dateStr) {
      let dated = null;
      if (dateStr instanceof Date) {
        dated = dateStr;
      } else {
        dated = parseISO(dateStr);
      }
      return format(dated, formatDate);
    }
    return String(dateStr);
  }

  static toParse(dateStr: string): Date {
    let dated = parseISO(dateStr);
    return new Date(dated.getFullYear(), dated.getMonth() - 1, dated.getDate(), 12, 0, 0);
  }

  static toParseFormatedDateAndTime(dateStr: string, time: string): Date {
    let dated = parse(dateStr, 'dd/MM/yyyy', new Date());
    let datedT = new Date(dated.getFullYear(), dated.getMonth() - 1, dated.getDate(), +time.substring(0, 2), +time.substring(3, 5), 0)
    return datedT;
  }

  static getDateAddDays(amountDays: number, date: Date = new Date()) {
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    return addDays(date, amountDays);
  }

  static getDateAddDaysFormated(amountDays: number, date: Date = new Date(), formatDate = 'dd/MM/yyyy') {
    return format(this.getDateAddDays(amountDays, date), formatDate);
  }

  static getDateAddMonths(amountMonths: number, date: Date = new Date()) {
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    return addMonths(date, amountMonths);
  }

  static getDateAddMonthsFormated(amountMonths: number, date: Date = new Date(), formatDate = 'dd/MM/yyyy') {
    return format(this.getDateAddMonths(amountMonths, date), formatDate);
  }

  // static parseNgbDateStructToDate(value: NgbDateStruct): Date {
  //   if (value) {
  //     return new Date(Date.UTC(value.year, value.month - 1, value.day, 12, 0, 0));
  //   }
  //   return null;
  // }

  // static parseDateToNgbDateStruct(value: Date | string): NgbDateStruct {
  //   if (value) {
  //     if (typeof value === 'string') {
  //       value = new Date(value);
  //     }
  //     return { day: value.getDate(), month: value.getMonth() + 1, year: value.getFullYear() } as NgbDateStruct;
  //   }
  //   return null;
  // }

  static getDateAddDaysInitialTime(amountDays: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    let newDate = addDays(date, amountDays);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    let newDateInitial = new Date(Date.UTC(year, month, day, 0, 0, 0));
    newDateInitial.setTime(newDateInitial.getTime() + newDateInitial.getTimezoneOffset() * 60 * 1000); // deserializa
    return newDateInitial;
  }

  static getDateAddDaysFinalTime(amountDays: number, date: Date = new Date()) {
    date.toLocaleString('pt-BR', {
      timeZone: 'America/Sao_Paulo'
    });
    date = setHours(date, 0);
    date = setMinutes(date, 0);
    date = setSeconds(date, 0);
    let newDate = addDays(date, amountDays);
    let day = newDate.getDate();
    let month = newDate.getMonth();
    let year = newDate.getFullYear();
    var newDateFinal = new Date(Date.UTC(year, month, day, 23, 59, 59));
    newDateFinal.setTime(newDateFinal.getTime() + newDateFinal.getTimezoneOffset() * 60 * 1000); // deserializa
    return newDateFinal;
  }

  static isAfter(actualDate: Date, compareDate: Date) {
    return isAfter(actualDate, compareDate);
  }

  static isBefore(actualDate: Date, compareDate: Date) {
    return isBefore(actualDate, compareDate);
  }

  static isSameDate(dateLeft: Date, dateRight: Date) {

    dateLeft = setHours(dateLeft, 0);
    dateLeft = setMinutes(dateLeft, 0);
    dateLeft = setSeconds(dateLeft, 0);
    dateLeft = setMilliseconds(dateLeft, 0);

    dateRight = setHours(dateRight, 0);
    dateRight = setMinutes(dateRight, 0);
    dateRight = setSeconds(dateRight, 0);
    dateRight = setMilliseconds(dateRight, 0);

    // console.log(isEqual(dateLeft, dateRight));
    return isEqual(dateLeft, dateRight);
  }

  static getDIfferenceInDaysFromNow(date: Date) {
    const differenceTime = new Date().getTime() - date.getTime();
    return differenceTime / (1000 * 3600 * 24);
  }

}