import { Component, ElementRef, EventEmitter, Injector, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractComponent } from '../../../commons/abstract.component';
import { Fila } from '../../../models/fila.model';
import { Senha } from '../../../models/senha.model';
import { SenhaService } from '../../../services/senha.service';
import { tree } from 'd3';

@Component({
  selector: 'nova-senha-component',
  templateUrl: './nova-senha.component.html',
})
export class NovaSenhaComponent extends AbstractComponent implements OnInit, OnDestroy {

  @ViewChild('btnCloseModalNovaSenha') btnCloseModalNovaSenha: ElementRef;
  @ViewChild('btnShowModalNovaSenha') btnShowModalNovaSenha: ElementRef;

  @Input('filas') filas: Array<Fila>;

  @Output()
  public onSave: EventEmitter<string> = new EventEmitter<string>();

  loading: boolean = false;

  dadosSenha: Senha;
  filaSelecionada: Fila;

  showNormalPreferencial: boolean = false;
  showDadosContatoFila: boolean = false;
  showConfirmarButton: boolean = false

  constructor(
    private senhaService: SenhaService,
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.dadosSenha = {} as Senha;
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.closeModdal();
  }

  clear() {
    this.dadosSenha = {
      nome: '',
      numero: '',
      email: '',
    } as Senha;
    this.dadosSenha.data = new Date();
    this.dadosSenha.compareceu = false;
    this.dadosSenha.saiuDaFila = false;

    this.filaSelecionada = null;
    this.showNormalPreferencial = false;
    this.showDadosContatoFila = false;
    this.showConfirmarButton = false;
  }

  exibirModalGerarNovaSenha() {
    this.clear();
    this.btnShowModalNovaSenha.nativeElement.click();
  }

  selecionarFila(fila: Fila) {
    this.filaSelecionada = fila;
    if (fila.preferencial === true) {
      this.showNormalPreferencial = true;
    } else if (fila.dadosContato === true) {
      this.showDadosContatoFila = true;
      this.showConfirmarButton = true;
    } else {
      this.gerarNovaSenhaMetadata()
    }
  }

  selecionarNormalPreferencial(preferencial: boolean) {
    this.dadosSenha.preferencial = preferencial;
    if (this.filaSelecionada.dadosContato === true) {
      this.showDadosContatoFila = true;
      this.showConfirmarButton = true;
    } else {
      this.gerarNovaSenhaMetadata();
    }
  }

  gerarNovaSenhaMetadata() {
    this.loading = true;
    const metadados = {
      permiteCadastro: true,
      permiteWhatsApp: true,
      app: 'LITE',
      campos: []
    }
    if (!!this.dadosSenha.nome) {
      metadados['nome'] = this.dadosSenha.nome;
    }
    if (!!this.dadosSenha.numero) {
      metadados['numero'] = this.dadosSenha.numero;
    }
    if (!!this.dadosSenha.email) {
      metadados['email'] = this.dadosSenha.email;
    }

    metadados.campos = [];
    if (!!this.showDadosContatoFila && this.showDadosContatoFila) {
      if (!!this.filaSelecionada.campos && this.filaSelecionada.campos.length > 0) {
        this.filaSelecionada.campos.forEach((campo: any, index: number) => {
          const newCampoValue = {
            key: campo.key,
            value: (document.getElementById('campo_' + index) as any).value
          }
          metadados.campos.push(newCampoValue);
        });
      }
    }

    const idFila = this.filaSelecionada._id;
    if (this.dadosSenha.preferencial === true) {
      this.senhaService.gerarNovaSenhaPreferencialMetadata(idFila, this.dadosSenha.preferencial, metadados).subscribe({
        next: (senha: Senha) => {
          this.alertService.success();
          this.onSave.emit(idFila);
          this.closeModdal();
        },
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.loading = false
      });
    } else {
      this.senhaService.gerarNovaSenhaMetadata(idFila, metadados).subscribe({
        next: (senha: Senha) => {
          this.alertService.success();
          this.onSave.emit(idFila);
          this.closeModdal();
        },
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.loading = false
      });
    }

  }

  closeModdal() {
    this.clear();
    this.btnCloseModalNovaSenha.nativeElement.click();
  }

}
