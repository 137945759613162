import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, FirebaseService, UploadTypeEnum, Usuario, UsuarioService } from 'lib-smart-core';

@Component({
  selector: 'usuario-forms',
  templateUrl: './usuario-forms.component.html'
})
export class UsuarioFormsComponent extends AbstractComponent implements OnInit {

  usuario: Usuario;
  isNew: boolean = true;

  usuarioDeGrupo = false;

  constructor(
    private usuarioService: UsuarioService,
    private firebaseService: FirebaseService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.usuario = {} as Usuario;
    let idEmpresa: string = super.getParam('idEmpresa');
    let idGrupo: string = super.getParam('idGrupo');
    if (idEmpresa) {
      this.usuario = {} as Usuario
    } else if (idGrupo) {
      this.usuarioDeGrupo = true;
      this.usuario = {} as Usuario
      this.usuario.admin = true; // todos usuarios do grupo sao admin
    } else {
      let id: string = super.getParam('id');
      if (id) {
        this.isNew = false;
        this.getUsuarioPorId(id.toString());
      }
    }
  }

  getUsuarioPorId(id: string) {
    return this.usuarioService.getById(id).subscribe(
      (usuario) => {
        this.usuario = usuario;
        if (this.usuario.grupo) {
          this.usuarioDeGrupo = true; // todos usuarios do grupo sao admin
        }
      },
      err => this.alertService.error(err.error.message)
    )
  }

  onSubmit(): void {
    if (this.usuarioDeGrupo) {
      this.usuario.admin = true;
    }
    if (this.isNew) {
      this.usuarioService.create(this.usuario)
        .subscribe(
          franquia => {
            super.goBack();
            this.alertService.success('Operação realizada com sucesso');
          },
          err => this.alertService.error(err.error.message)
        );
    } else {
      this.usuarioService.update(this.usuario)
        .subscribe(
          usuario => {
            super.goBack();
            this.alertService.success('Operação realizada com sucesso');
          },
          err => this.alertService.error(err.error.message)
        );
    }

  }

  private file: File;

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localImage') as HTMLElement;
    element.click();
  }

  onChange(event) {
    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 500000) { // 500 KB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 500 KB.');
      return;
    }

    if (!(files[0].type === 'image/jpeg' || files[0].type === 'image/png')) {
      this.alertService.warning('Imagem inválida. O arquivo de imagem deve estar no formato JPG ou PNG.');
      return;
    }

    this.file = files[0];
    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        console.log('uploading.... ' + this.usuario._id);
        let filename = this.usuario.email + '_' + Date.now();
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.USUARIO).then(snapshot => {
          console.log(snapshot.url);
          this.usuario.image = snapshot.url;
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  removerImagem() {
    this.firebaseService.deleteFile(this.usuario.image).then(snapshot => {
      this.usuario.image = null;
      this.usuarioService.update(this.usuario).subscribe(
        usuario => {
          super.goBack();
          this.alertService.success('Operação realizada com sucesso');
        },
        err => this.alertService.error(err.error.message)
      );
    });
  }

}