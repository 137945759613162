import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Avaliacao } from '../models/avaliacao.model';

@Injectable({ providedIn: 'root' })
export class AvaliacaoService extends AbstractService<Avaliacao>  {

  getURLBase(): string {
    return '/avaliacoes/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Avaliacao): void {
    delete obj._id;
  }
  preUpdate(obj: Avaliacao): void {
  }

  getRelatorioPorEmpresaTABLET(idEmpresa: string): Observable<Avaliacao[] | any> {
    return super.get('relatorio/empresa/' + idEmpresa)
  }

  getResultadoAvaliacaoPorFranquia(idFranquia: string): Observable<Avaliacao[] | any> {
    return super.get('resultado/avaliacao/geral/' + idFranquia);
  }

  getResultadoAvaliacaoDetalhadaPorFranquia(idFranquia: string): Observable<Avaliacao[] | any> {
    return super.get('resultado/avaliacao/geral/detalhada/' + idFranquia);
  }

  getResultadoAvaliacaoFranquiaDetalhada(idFranquia: string): Observable<Avaliacao[] | any> {
    return super.get('resultado/avaliacao/franquia/detalhada/' + idFranquia);
  }

}