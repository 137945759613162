import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService, Pager } from 'lib-smart-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';

@Component({
  selector: 'empresa-lista-component',
  templateUrl: './empresa-lista.component.html'
})
export class EmpresaListaComponent extends AbstractComponent implements OnInit {

  pager: Pager<Empresa> = new Pager<Empresa>({ perPage: 50 });

  empresas: Empresa[];

  idEmpresaSelecionada: string = null;
  situacaoEmpresaSelecionada: string = null;

  listObservable: Observable<Pager<Empresa>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  constructor(
    private empresaService: EmpresaService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.searchParams = {
      situacao: 'ATIVA'
    };
    this.getEmpresas();
  }

  getEmpresas() {
    return this.empresaService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe(
      (pager: Pager<Empresa>) => {
        this.pager = pager;
        this.setupObservableSearch();
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  setIDEmpresaAtivarInativar(idEmpresa: string, situacaoEmpresa: string): void {
    this.idEmpresaSelecionada = idEmpresa;
    this.situacaoEmpresaSelecionada = situacaoEmpresa;
  }

  ativarInativarEmpresa(): void {
    if (this.idEmpresaSelecionada != null) {
      if (this.situacaoEmpresaSelecionada === 'ATIVA') {
        this.empresaService.inativar(this.idEmpresaSelecionada).subscribe(
          () => {
            this.getEmpresas();
            this.alertService.success('Operação realizada com sucesso');
          },
          err => {
            console.log(err);
            this.alertService.error(err.error.message);
          }
        );
      } else {
        this.empresaService.ativar(this.idEmpresaSelecionada).subscribe(
          () => {
            this.getEmpresas();
            this.alertService.success('Operação realizada com sucesso');
          },
          err => {
            console.log(err);
            this.alertService.error(err.error.message);
          }
        );
      }
    }
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getEmpresas();
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.empresaService.getAllPagerQuerySearch(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Empresa>) => {
      this.pager = pager;
    });
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  loadByStatus(situacao: string) {
    this.searchParams['situacao'] = situacao;
    this.getEmpresas();
  }

}