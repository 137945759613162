import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { UsuarioFormsComponent } from "./usuario-forms.component";
import { UsuarioListaComponent } from "./usuario-lista.component";
import { UsuarioSenhaFormsComponent } from './usuario-senha-forms.component';
import { UsuarioComponent } from "./usuario.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    UsuarioComponent,
    UsuarioListaComponent,
    UsuarioFormsComponent,
    UsuarioSenhaFormsComponent
  ],
  exports: [
    UsuarioComponent,
    UsuarioListaComponent,
    UsuarioFormsComponent,
    UsuarioSenhaFormsComponent
  ],
  providers: []
})

export class UsuarioModule { }