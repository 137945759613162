import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../commons/abstract.service';

@Injectable({ providedIn: 'root' })
export class MapboxService extends AbstractService<any> {

  getURLBase(): string {
    return '/integrations/mapbox/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: any): void {
    delete obj._id;
  }
  preUpdate(obj: any): void {
  }

  getPostcode(latitude: string, longitude: string): Observable<any> {
    return super.get(`postcode?latitude=${latitude}&longitude=${longitude}`);
  }

}
