import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Pergunta } from '../../models/pergunta.model';

@Injectable({ providedIn: 'root' })
export class PublicPerguntaService {

  getURLBase(): string {
    return '/public/perguntas/';
  }

  constructor(private http: HttpClient) {
  }

  getAllPorQuestionario(idQuestionario: string): Observable<Pergunta[] | any> {
    return this.http.get(`${this.getURLBase()}list/${idQuestionario}`);
  }

  getAllPorQuestionarioPager(page: number, perPage: number, idQuestionario: string): Observable<Pergunta[] | any> {
    return this.http.get(`${this.getURLBase()}pager/${page}/${perPage}/questionario/${idQuestionario}`);
  }

  getPerguntasPorQuestionario(idQuestionario) {
    return this.http.get(`${this.getURLBase()}list/${idQuestionario}`);
  }

}
