import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AlertModule, AuthGuard, CONSTANTES, ENV, JwtService, customHttpProvider } from 'lib-smart-core';
import { ToastrModule } from 'ngx-toastr';
import { Constantes } from '../environments/constantes';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { EmpresaModule } from './modules/empresa/empresa.module';
import { FranquiaModule } from './modules/franquia/franquia.module';
import { LayoutModule } from './modules/layout/layout.module';
import { LoginComponent } from './modules/login/login.component';
import { PagamentoModule } from './modules/pagamento/pagamento.module';
import { RelatorioModule } from './modules/relatorio/relatorio.module';
import { SenderModule } from './modules/sender/sender.module';
import { UsuarioModule } from './modules/usuario/usuario.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LayoutModule,
    AppRoutingModule,
    HttpClientModule,
    EmpresaModule,
    UsuarioModule,
    FranquiaModule,
    PagamentoModule,
    RelatorioModule,
    SenderModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AlertModule
  ],
  providers: [
    AuthGuard,
    customHttpProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtService, multi: true },
    { provide: ENV, useValue: environment },
    { provide: CONSTANTES, useValue: Constantes },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
