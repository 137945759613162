import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Fila } from "../models/fila.model";

@Injectable({ providedIn: 'root' })
export class FilaService extends AbstractService<Fila> {

  getURLBase(): string {
    return '/filas/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Fila): void {
    delete obj._id;
  }
  preUpdate(obj: Fila): void {
  }

  getFilasPorFranquia(idFranquia: string): Observable<Fila[] | any> {
    return super.get('franquia/' + idFranquia);
  }

  getFilasPorFranquiaPreferencial(idFranquia: string): Observable<Fila[] | any> {
    return super.get('franquia/' + idFranquia + '/preferencial');
  }

  getFilasPorFranquiaPublic(idFranquia: string): Observable<Fila[] | any> {
    return this.http.get('/public/filas/franquia/' + idFranquia);
  }

  alterarSenhaChamadaManualInicio(_id: string, chamadaManualInicio: number) {
    return super.put('update/chamadaManualInicio', { _id, chamadaManualInicio })
  }

  updateOrdemFilas(filas: Array<Partial<Fila>>) {
    return super.put('update/ordemFilas', { filas })
  }

}