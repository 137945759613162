import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { map } from 'rxjs/operators';
import { CONSTANTES, Constantes } from '../commons/injection.token';
import { Usuario } from '../models/usuario.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  constructor(private http: HttpClient, @Inject(CONSTANTES) private constantes: Constantes) {
  }

  login(email: string, senha: string) {
    return this.http.post('/auth', { email: email, senha: senha })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          let currentUser: any = response;
          if (currentUser && currentUser.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.constantes['currentUser'], JSON.stringify(currentUser));
          } else {
            // throwError(() => new Error(currentUser.message));
            throw observableThrowError(currentUser.message);
          }
        })
      );
  }

  loginFranchise(codigo: string) {
    console.log('login');
    return this.http.post('/auth/franchise', { codigo: codigo })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          let currentUser: any = response;
          if (currentUser && currentUser.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.constantes['currentUser'], JSON.stringify(currentUser));
          } else {
            throw observableThrowError(currentUser.message);
          }
        })
      );
  }

  loginImpersonate(email: string, idEmpresa: string) {
    return this.http.post('/auth/impersonate', { email, idEmpresa })
      .pipe(
        map((response) => {
          // login successful if there's a jwt token in the response
          let currentUser: any = response;
          if (currentUser && currentUser.token) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(this.constantes['currentUser'], JSON.stringify(currentUser));
          } else {
            // throw Observable.throw(currentUser.message);
            throw observableThrowError(currentUser.message);
          }
        })
      );
  }

  refreshJwtTokenUserLogged(): Observable<Usuario> {
    return this.http.get('/auth/refresher/')
      .pipe(
        map((response: any) => {
          let currentUser: any = response;
          if (currentUser && currentUser.token) {
            localStorage.setItem(this.constantes['currentUser'], JSON.stringify(currentUser));
            return currentUser.user as Usuario;
          } else {
            throw observableThrowError(currentUser.message);
          }
        })
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem(this.constantes['currentUser']);
    localStorage.removeItem(this.constantes['currentFranquia']);
    localStorage.removeItem(this.constantes['currentMesa']);
  }

}