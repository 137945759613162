import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../../commons/abstract.service';
import { Pager } from '../../commons/pager';
import { TV } from '../../models/tv.model';

@Injectable({ providedIn: 'root' })
export class PublicTVService extends AbstractService<TV> {

  getURLBase(): string {
    return '/public/tvs/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: TV): void {
    delete obj._id;
  }
  preUpdate(obj: TV): void {
  }

  getTVsPorFranquiaPager(page: number, perPage: number, idFranquia: string): Observable<Pager<TV> | any> {
    return this.http.get(this.getURLBase() + 'pager/' + page + '/' + perPage + '/' + idFranquia);
  }

  getByIdPublic(idTV: string): Observable<TV | any> {
    return this.http.get('/public/tvs/' + idTV);
  }

}