export class ChartHelper {

  static COLORS = [
    '#3179ce',
    '#0288D1',
    '#FF4081',
    '#4CAF50',
    '#D32F2F',
    '#FFC107',
    '#673AB7',
    '#FF5722',
    '#CDDC39',
    '#795548',
    '#607D8B',
    '#009688',
    '#E91E63',
    '#9E9E9E',
    '#E040FB',
    '#00BCD4',
  ];

  static colorsDefault = {
    backgroundColor: [
      'rgba(49, 121, 206, 0.4)',
      'rgba(255, 99, 132, 0.4)',
      'rgba(54, 162, 235, 0.4)',
      'rgba(255, 206, 86, 0.4)',
      'rgba(75, 192, 192, 0.4)',
      'rgba(153, 102, 255, 0.4)',
      'rgba(255, 159, 64, 0.4)'
    ],
    borderColor: [
      'rgba(49, 121, 206, 1)',
      'rgba(255,99,132,1)',
      'rgba(54, 162, 235, 1)',
      'rgba(255, 206, 86, 1)',
      'rgba(75, 192, 192, 1)',
      'rgba(153, 102, 255, 1)',
      'rgba(255, 159, 64, 1)'
    ],
    borderWidth: 2,
  };


  static getRandomData(max, min?) {
    return Math.floor(Math.random() * ((max || 100) - (min || 0))) + (min || 0);
  }

  static getRandomColors(count) {
    if (count && count > this.COLORS.length) {
      throw new Error('Have not enough colors');
    }

    var clrLeft = count || this.COLORS.length;
    var source = [].concat(this.COLORS);
    var result = [];

    while (clrLeft-- > 0) {
      result.unshift(source[source.length > 1 ? this.getRandomData(source.length - 1) : 0]);
      source.splice(source.indexOf(result[0]), 1);
    }

    this.shuffle(result);

    return result;
  }

  static shuffle(a) {
    var j;
    var x;
    var i;

    for (i = a.length; i; i -= 1) {
      j = Math.floor(Math.random() * i);
      x = a[i - 1];
      a[i - 1] = a[j];
      a[j] = x;
    }
  }

  static hexToRgba(color, opacity) {
    var hex = color.replace('#', '');
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);

    return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + opacity + ')';
  }

  static optionsDefault = {
    legend: { display: true },
    responsive: true,
    tooltips: {
      intersect: !1
    }
    , hover: {
      intersect: !0
    }
    , plugins: {
      filler: {
        propagate: !1
      }
    }
    , scales: {
      xAxes: [{
        reverse: !0, gridLines: {
          color: "rgba(0,0,0,0.05)"
        }
      }
      ], yAxes: [{
        ticks: {
          stepSize: 20
        }
        , display: !0, borderDash: [5, 5], gridLines: {
          color: "rgba(0,0,0,0)", fontColor: "#fff"
        }
      }
      ]
    }
  };

  static optionsDefaultWhitoutLegend = {
    ...this.optionsDefault,
    legend: { display: false },
  };

  static getRandomColor() {
    let letters = '0123456789ABCDEF'.split('');
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

}