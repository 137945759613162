import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { ChamadaComposite } from '../models/chamada.composite';
import { Senha } from '../models/senha.model';

@Injectable({ providedIn: 'root' })
export class SenhaService extends AbstractService<any> {

  getURLBase(): string {
    return '/senhas/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: any): void {
    delete obj._id;
  }
  preUpdate(obj: any): void {
  }

  getChamadaDiariaPorFila(idFila: string): Observable<ChamadaComposite | any> {
    return super.get('chamada/' + idFila);
  }

  getChamadaDiariaPorFilaPager(page: number, perPage: number, idFila: string): Observable<ChamadaComposite | any> {
    return super.get('chamada/pager/' + page + '/' + perPage + '/' + idFila);
  }

  getChamadaDiariaPorFilaPreferencial(idFila: string): Observable<ChamadaComposite | any> {
    return super.get('chamada/' + idFila + '/preferencial');
  }

  getChamadaDiariaPorFilaPagerPreferencial(page: number, perPage: number, idFila: string): Observable<ChamadaComposite | any> {
    return super.get('chamada/pager/' + page + '/' + perPage + '/' + idFila + '/preferencial');
  }

  chamarProximaSenha(idFila: string, idMesa: string): Observable<Senha | any> {
    return super.get('chamarProximaSenha/' + idFila + '/' + idMesa);
  }

  chamarProximaSenhaManual(idFila: string, idMesa: string, startedNumber: number): Observable<Senha | any> {
    return super.get('chamarProximaSenhaManual/' + idFila + '/' + idMesa + '/' + startedNumber);
  }

  chamarSenhaNaChamada(idFila: string, idMesa: string, senhaChamada: string): Observable<Senha | any> {
    return super.get('chamarSenhaNaChamada/' + idFila + '/' + idMesa + '/' + senhaChamada);
  }

  rechamarSenha(idSenha: string): Observable<Senha | any> {
    return super.get('rechamarSenha/' + idSenha);
  }

  encaminhar(idFila: string, idSenha: string, idFranquia: string): Observable<Senha | any> {
    return super.get('encaminhar/' + idFila + "/" + idSenha + "/" + idFranquia);
  }

  countChamadaPorFila(idFila: string): Observable<number | any> {
    return this.http.get('/senhas/count/chamadas/' + idFila);
  }

  countChamadaAllFilas(idFranquia: string): Observable<number | any> {
    return this.http.get('/senhas/count/all/chamadas/' + idFranquia);
  }

  previsaoAtendimento(idFranquia: string): Observable<number | any> {
    return super.get('previsaoAtendimento/' + idFranquia);
  }

  historicoChamadas(idFranquia: string) {
    return super.get('historico/' + idFranquia);
  }

  gerarNovaSenha(idFranquia: string) {
    return super.get('gerarNovaSenha/' + idFranquia);
  }

  gerarNovaSenhaPreferencial(idFila: string, preferencial = false) {
    return this.http.get(`/public/senhas/gerarNovaSenha/${idFila}${preferencial ? '/preferencial' : ''}`);
  }

  gerarNovaSenhaPublic(idFranquia: string) {
    return this.http.get('/public/senhas/gerarNovaSenha/' + idFranquia);
  }

  gerarNovaSenhaMetadata(idFila, metadata) {
    return this.http.post(`/public/senhas/gerarNovaSenhaMetadata/${idFila}`, metadata);
  }

  gerarNovaSenhaPreferencialMetadata(idFila, preferencial = false, metadata) {
    return this.http.post(`/public/senhas/gerarNovaSenhaMetadata/${idFila}${preferencial ? '/preferencial' : ''}`, metadata);
  }

  imprimirLocal(url: string, obj: any) {
    return this.http.post(url, obj);
  }

  chamadaAnonima(idFranquia: string, idFila: string, senha: string): Observable<Senha | any> {
    return super.post('chamadaAnonima/' + idFranquia + '/' + idFila, { senha: senha });
  }

  historicoChamadasFullDay(page, perPage, idFranquia) {
    return super.get('historico/full/day/' + page + '/' + perPage + '/' + idFranquia);
  }

  pagerClientes(page: number, perPage: number, idEmpresa: string): Observable<any> {
    return super.get('pager/clientes/' + page + '/' + perPage + '/empresa/' + idEmpresa);
  }

  pagerClientesFranquia(page: number, perPage: number, idEmpresa: string, idFranquia: string): Observable<any> {
    return super.get('pager/clientes/' + page + '/' + perPage + '/empresa/' + idEmpresa + '/franquia/' + idFranquia);
  }

  gerarSenhaZeroParaLocalizacao(idFranquia: string, idLocalizacao: string) {
    return super.get('gerarSenhaZeroParaLocalizacao/franquia/' + idFranquia + '/localizacao/' + idLocalizacao);
  }

  getChamadaPorIdSenha(idSenha: string) {
    return super.get('get/chamada/senha/' + idSenha);
  }

  updateTokenSenha(idSenha: string, token: string) {
    return this.http.post(this.getURLBase() + 'update/token/senha/' + idSenha, { token: token });
  }

  habilitarWhatsApp(idSenha: string, nome: string, numero: string) {
    return super.post('habilitar/whatsapp/' + idSenha, { nome, numero });
  }

  chamarSenhaNaChamadaPorId(idMesa, senhaChamada) {
    return super.get('chamarSenhaNaChamadaPorId/' + idMesa + '/' + senhaChamada);
  }

  enviarMensagemParaSenha(idSenha, mensagem) {
    return super.post('enviarMensagemParaSenha/' + idSenha, { message: mensagem });
  }

  getChamadaSenhaDiarioGeralPager(page, perPage, idFranquia) {
    return super.get('chamada/pager/geral/' + page + '/' + perPage + '/' + idFranquia);
  }

  compareceuAtendimentoPager(idSenha, compareceu) {
    return super.get('compareceu/atendimento/pager?idSenha=' + idSenha + '&compareceu=' + compareceu);
  }

  getCountsNewDashboads(idEmpresa: string, dateStart: string, dateEnd: string, idFranquia?: string): Observable<any> {
    return super.get(`dashboard/counts?idEmpresa=${idEmpresa}&idFranquia=${idFranquia}&dataInicio=${dateStart}&dataFim=${dateEnd}`);
  }

}
