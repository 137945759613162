import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Empresa } from '../models/empresa.model';

@Injectable({ providedIn: 'root' })
export class EmpresaService extends AbstractService<Empresa>  {

  getURLBase(): string {
    return '/empresas/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }
  preCreate(obj: Empresa): void {
    delete obj._id;
  }
  preUpdate(obj: Empresa): void {
  }

  ativar(idEmpresa: string): Observable<Empresa | any> {
    return this.http.get(this.getURLBase() + 'ativar/' + idEmpresa);
  }

  inativar(idEmpresa: string): Observable<Empresa | any> {
    return this.http.get(this.getURLBase() + 'inativar/' + idEmpresa);
  }

  getCountFranquias(idEmpresa: string): Observable<number | any> {
    return this.http.get('/franquias/count/empresa/' + idEmpresa);
  }

  getCountUsuarios(idEmpresa: string): Observable<number | any> {
    return this.http.get('/usuarios/count/empresa/' + idEmpresa);
  }

  getCountMesas(idEmpresa: string): Observable<number | any> {
    return this.http.get('/mesas/count/empresa/' + idEmpresa);
  }

  getCountAtendimentos(idEmpresa: string): Observable<number | any> {
    return this.http.get('/atendimentos/count/empresa/' + idEmpresa);
  }

  getCountSenhas(idEmpresa: string): Observable<number | any> {
    return this.http.get('/senhas/count/empresa/' + idEmpresa);
  }

  getPublic(idEmpresa: string): Observable<number | any> {
    return this.http.get('/public/empresas/' + idEmpresa);
  }

  downloadFromFirebase(url: string): Observable<any> {
    return this.post('download/firebase', { url }, { observe: 'body', responseType: 'arraybuffer' as 'json' });
  }

  dasboardChartUltimos7Dias(idEmpresa: string): Observable<any> {
    return this.get('dashboard/chart/ultimos7dias/' + idEmpresa);
  }

  dasboardChartCounts(idEmpresa: string, idFranquia: string, startDateStr: string, endDateStr: string): Observable<any> {
    let urling = `idEmpresa=${idEmpresa}&idFranquia=${idFranquia}&startDate=${startDateStr}&endDate=${endDateStr}`;
    return this.get(`dashboard/chart/counts?${urling}`);
  }

}