import { Component } from '@angular/core';
import { Constantes } from '../environments/constantes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {

  usuarioLogado(): Boolean {
    let usuarioLoagdo = false;
    let currentUser = JSON.parse(localStorage.getItem(Constantes.currentUser));
    if (currentUser) {
      usuarioLoagdo = true;
    }
    return usuarioLoagdo;
  }

  currentUser(): any {
    return JSON.parse(localStorage.getItem(Constantes.currentUser));
  }

}
