<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Sender</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li>
        <a>Sender </a>
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #senderForm="ngForm" novalidate>
            <div class="form-group">
              <label>Nome<span class="text-danger">*</span></label>
              <input type="text" placeholder="Insira o Nome do Sender" class="form-control" name="name" id="name"
                #name="ngModel" [(ngModel)]="sender.name" required>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Tipo<span class="text-danger">*</span></label>
                  <select name="type" id="type" [(ngModel)]="sender.type" class="form-control" required>
                    <option value="WHATSAPP">WHATSAPP</option>
                    <option value="SMS">SMS</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <div class="form-group">
                  <label>Implementação (canal)<span class="text-danger">*</span></label>
                  <select name="impl" id="impl" [(ngModel)]="sender.impl" class="form-control" required>
                    <option value="APPBEE">APPBEE (WhatsApp)</option>
                    <option value="BOTCONVERSA">BOTCONVERSA (WhatsApp)</option>
                    <option value="SMARTLINEWAWEB">SMARTLINEWAWEB (WhatsApp)</option>
                    <option value="MESSAGEBIRD">MESSAGEBIRD (WhatsApp)</option>
                    <option value="MESSAGEBIRD">SNSSMS (SMS)</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="form-group">
              <label>Metadata<span class="text-danger">*</span></label>
              <textarea rows="10" class="form-control" name="metadata" id="metadata" #metadata="ngModel"
                [(ngModel)]="metadataStr" required></textarea>
            </div>
            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!senderForm.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>