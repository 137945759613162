import { InjectionToken } from "@angular/core";

export const ENV = new InjectionToken<Environment>('smart_environment');

export const CONSTANTES = new InjectionToken<Constantes>('smart_constantes');

export interface Environment {
  [key: string]: string | boolean | object | unknown;
}

export interface Constantes {
  [key: string]: string;
}