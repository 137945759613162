<div id="wrapper">

  <!-- Menu lateral -->

  <menu-component *ngIf="usuarioLogado()"></menu-component>

  <div id="page-wrapper" class="gray-bg" [ngClass]="!usuarioLogado()? 'page-wrapper-login': ''">
    <div class="row border-bottom" *ngIf="usuarioLogado()">
      <nav class="navbar navbar-static-top white-bg" role="navigation" style="margin-bottom: 0">
        <div class="navbar-header">
          <a class="navbar-minimalize minimalize-styl-2 btn btn-primary"><i class="fa fa-bars"></i> </a>
          <!--<form role="search" class="navbar-form-custom" method="post" action="#">
                      <div class="form-group">
                          <input type="text" placeholder="Search for something..." class="form-control" name="top-search" id="top-search">
                      </div>
                  </form>-->
        </div>
        <ul class="nav navbar-top-links navbar-right">
          <li>
            <a [routerLink]="['/login']">
              <i class="fa fa-sign-out"></i> Sair
            </a>
          </li>
        </ul>

      </nav>
    </div>
    <alert></alert>
    <router-outlet></router-outlet>
    <div class="footer">
      <div class="pull-right">
        <strong>v6.16.2</strong>
      </div>
      <div>
        Smartline &copy; 2025
      </div>
    </div>

  </div>
</div>
