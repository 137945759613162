<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-sm-6">
    <h2>Empresa</h2>
    <ol class="breadcrumb">
      <li class="active">
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
    </ol>
  </div>
  <div class="col-sm-6">
    <div class="title-action">
      <a class="btn btn-primary" *ngIf="isOwner" (click)="loginImpersonate()">Impersonate como {{ getEmailUsuarioLogado() }}</a>
      <a class="btn btn-primary" data-toggle="modal" data-target="#modalProdutos">Configuração de Produtos</a>
    </div>
  </div>
</div>

<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">

    <div class="col-md-8">
      <h2>{{ empresa?.nome }}<label class="badge badge-primary"> {{ empresa?.situacao }}</label></h2>
      <table class="table small">
        <tbody>
          <tr>
            <td>
              <strong>Responsável</strong> {{ empresa?.nomeResponsavel }}
            </td>
            <td>
              <strong>Telefone</strong> {{ empresa?.telefoneResponsavel }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>CNPJ</strong> {{ empresa?.cpfCnpj }}
            </td>
            <td>
              <strong>Data de Cadastro</strong> {{ empresa?.dataCadastro | date }}
            </td>
          </tr>
          <tr>
            <td>
              <strong>Licença</strong> {{ empresa?.licence | slice:0:16 | uppercase}}
            </td>
            <td>
              <strong>Data Expiração</strong> {{ empresa?.dataExpiracaoLicence | date : 'dd/MM/yyyy' }}
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="col-md-4">
      <div class="row m-t-xs">
        <div class="col-xs-6">
          <div class="ibox">
            <div class="ibox-content">
              <h5>Estabelecimentos</h5>
              <h2>{{totalFranquias}}</h2>
              <div class="progress progress-mini">
                <div style="width: 50%;" class="progress-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xs-6">
          <div class="ibox">
            <div class="ibox-content">
              <h5>Usuários</h5>
              <h2>{{totalUsuarios}}</h2>
              <div class="progress progress-mini">
                <div style="width: 90%;" class="progress-bar"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <franquia-lista> </franquia-lista>
  <usuario-lista [tipo]="'empresa'"></usuario-lista>

</div>

<div class="modal inmodal" id="modalProdutos" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog ">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Configuração de Produtos</h4>
        <small class="font-bold">Selecione os produtos que estão no pacote de compra</small>
      </div>
      <div class="modal-body">

        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th width="70%">Produtos</th>
                <th width="30%" style="text-align: right;">Ativo?</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>SmartFilas PRO</th>
                <td style="text-align: right;">
                  <a *ngIf="empresa.smartFilas" href="javascript:;" (click)="desativar('smartFilas')"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!empresa.smartFilas" href="javascript:;" (click)="ativar('smartFilas')"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                </td>
              </tr>
              <tr>
                <th>SmartFilas Lite</th>
                <td style="text-align: right;">
                  <a *ngIf="empresa.smartFilasLite" href="javascript:;" (click)="desativar('smartFilasLite')"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!empresa.smartFilasLite" href="javascript:;" (click)="ativar('smartFilasLite')"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                </td>
              </tr>
              <tr>
                <th>SmartAvalia</th>
                <td style="text-align: right;">
                  <a *ngIf="empresa.smartAvalia" href="javascript:;" (click)="desativar('smartAvalia')"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!empresa.smartAvalia" href="javascript:;" (click)="ativar('smartAvalia')"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                </td>
              </tr>
              <tr>
                <th>SmartPager</th>
                <td style="text-align: right;">
                  <a *ngIf="empresa.smartPager" href="javascript:;" (click)="desativar('smartPager')"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!empresa.smartPager" href="javascript:;" (click)="ativar('smartPager')"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
          <table class="table table-striped">
            <thead>
              <tr>
                <th width="70%">Funcionalidades</th>
                <th width="30%" style="text-align: right;">Ativo?</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Habilitar WhatsApp/SMS</th>
                <td style="text-align: right;">
                  <a *ngIf="empresa.senderEnabled" href="javascript:;" (click)="desativar('senderEnabled')"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!empresa.senderEnabled" href="javascript:;" (click)="ativar('senderEnabled')"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                </td>
              </tr>
              <tr>
                <th>Habilitar Localização</th>
                <td style="text-align: right;">
                  <a *ngIf="empresa.locationEnabled" href="javascript:;" (click)="desativar('locationEnabled')"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!empresa.locationEnabled" href="javascript:;" (click)="ativar('locationEnabled')"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                </td>
              </tr>
              <tr>
                <th>Habilitar API</th>
                <td style="text-align: right;">
                  <a *ngIf="empresa.apiEnabled" href="javascript:;" (click)="desativar('apiEnabled')"><i
                      class="fa fa-toggle-on fa-3x"></i></a>
                  <a *ngIf="!empresa.apiEnabled" href="javascript:;" (click)="ativar('apiEnabled')"><i
                      class="fa fa-toggle-off fa-3x"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
      </div>
    </div>
  </div>
</div>