import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Comentario } from '../models/comentario.model';

@Injectable({ providedIn: 'root' })
export class ComentarioService extends AbstractService<Comentario>  {

  getURLBase(): string {
    return '/comentario/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Comentario): void {
    delete obj._id;
  }
  preUpdate(obj: Comentario): void {
  }

}