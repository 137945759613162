<button
  *ngFor="let rating of ratings; index as i"
  [id]="'star-'+(i+1)"
  [color]="color"
  mat-icon-button
  (click)="onClick(i+1)"
  [matTooltip]="''+(i+1)"
  matTooltipPosition="above"
  data-starValue="0"
  class="btn-star">
  <mat-icon>
    {{showIcon(i)}}
  </mat-icon>
</button>
