<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="dropdown profile-element">
          <span>
            <img alt="image" class="img-circle" src="{{imageDefaultProfile}}" style="width: 42px;">
          </span>
          <a data-toggle="dropdown" class="dropdown-toggle" href="#">
            <span class="clear">
              <span class="block m-t-xs">
                <strong class="font-bold">{{currentUser?.usuario.nome}}</strong>
              </span>
              <span class="text-muted text-xs block">{{currentUser?.usuario.email}}
                <b class="caret"></b>
              </span>
            </span>
          </a>
          <ul class="dropdown-menu animated fadeInRight m-t-xs">
            <li>
              <a [routerLink]="['/login']">Logout</a>
            </li>
          </ul>
        </div>
        <div class="logo-element">
          <img src="/assets/img/logo-icon.svg" width="40" alt="logo" />
        </div>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/dashboard']">
          <i class="fa fa-th-large"></i>
          <span class="nav-label">Dashboard</span>
        </a>
      </li>
      <li routerLinkActive="active">
        <a [routerLink]="['/sender']">
          <i class="fa fa-sitemap"></i>
          <span class="nav-label">Sender</span>
        </a>
      </li>
      <!-- <li routerLinkActive="active">
        <a [routerLink]="['/grupo']">
          <i class="fa fa-sitemap"></i>
          <span class="nav-label">Grupos</span>
        </a>
      </li> -->
      <!-- <li routerLinkActive="active">
                <a [routerLink]="['/pagamento']">
                    <i class="fa fa-cubes"></i>
                    <span class="nav-label">Pagamentos</span>
                </a>
            </li>
            <li routerLinkActive="active">
                <a [routerLink]="['/relatorio']">
                    <i class="fa fa-user-o"></i>
                    <span class="nav-label">Relatórios</span>
                </a>
            </li> -->
    </ul>

  </div>
</nav>