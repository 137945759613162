import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService } from 'lib-smart-core';
import { environment } from "../../../environments/environment";

@Component({
  templateUrl: './empresa-detail.component.html'
})
export class EmpresaDetailComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;
  private routeParms: any;

  totalFranquias: number = 0;
  totalUsuarios: number = 0;

  constructor(
    private empresaService: EmpresaService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.empresa = {} as Empresa;
    let id: string = super.getParam('idEmpresa');
    if (id) {
      this.getEmpresa(id);
      this.getCountFranquias(id);
      this.getCountUsuarios(id);
    }
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id).subscribe(
      empresa => {
        this.empresa = empresa;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getCountFranquias(idEmpresa: string) {
    this.empresaService.getCountFranquias(idEmpresa).subscribe(
      count => {
        this.totalFranquias = count;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getCountUsuarios(idEmpresa: string) {
    this.empresaService.getCountUsuarios(idEmpresa).subscribe(
      count => {
        this.totalUsuarios = count;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  ativar(produto: string) {
    if (produto === 'smartFilas') {
      this.empresa.smartFilas = true;
    } else if (produto === 'smartFilasLite') {
      this.empresa.smartFilasLite = true;
    } else if (produto === 'smartAvalia') {
      this.empresa.smartAvalia = true;
    } else if (produto === 'smartPager') {
      this.empresa.smartPager = true;
    } else if (produto === 'senderEnabled') {
      this.empresa.senderEnabled = true;
    } else if (produto === 'locationEnabled') {
      this.empresa.locationEnabled = true;
    } else if (produto === 'apiEnabled') {
      this.empresa.apiEnabled = true;
    }
    this.update();
  }

  desativar(produto: string) {
    if (produto === 'smartFilas') {
      this.empresa.smartFilas = false;
    } else if (produto === 'smartFilasLite') {
      this.empresa.smartFilasLite = false;
    } else if (produto === 'smartAvalia') {
      this.empresa.smartAvalia = false;
    } else if (produto === 'smartPager') {
      this.empresa.smartPager = false;
    } else if (produto === 'senderEnabled') {
      this.empresa.senderEnabled = false;
    } else if (produto === 'locationEnabled') {
      this.empresa.locationEnabled = false;
    } else if (produto === 'apiEnabled') {
      this.empresa.apiEnabled = false;
    }
    this.update();
  }

  update() {
    this.empresaService.update(this.empresa).subscribe(
      empresa => {
        this.getEmpresa(empresa._id);
      },
      err => this.alertService.error(err.error.message)
    );
  }

  loginImpersonate() {
    const urlRedirect = `${environment.controleUrl}/redirect?email=${this.getEmailUsuarioLogado()}&idEmpresa=${this.empresa._id}`
    window.open(urlRedirect, '_blank');
  }

  private getCurrentUserEncoded(payload: any) {
    return btoa(payload);
  }

  public get isOwner() {
    let result = false;
    let emailLogged: string = super.getEmailUsuarioLogado();
    let emailOwners = super.emailOwners;
    for (let i = 0; i < super.emailOwners.length; i++) {
      if (emailLogged.includes(emailOwners[i])) {
        result = true;
        break;
      }
    }
    return result;
  }

}
