import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractService } from '../commons/abstract.service';
import { Localizacao } from '../models/localizacao.model';

@Injectable({ providedIn: 'root' })
export class LocalizacaoService extends AbstractService<Localizacao>  {

  getURLBase(): string {
    return '/localizacao/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Localizacao): void {
    delete obj._id;
  }
  preUpdate(obj: Localizacao): void {
  }

  ativar(id: string) {
    return super.get('ativar/' + id);
  }

  desativar(id: string) {
    return super.get('desativar/' + id);
  }

  listByFranquia(idFranquia: string) {
    return super.get('list/by/franquia/' + idFranquia);
  }

}
