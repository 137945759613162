import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService, Sender, SenderService } from 'lib-smart-core';

@Component({
  templateUrl: './empresa-forms.component.html'
})
export class EmpresaFormsComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;
  private isNew: boolean = true;

  private file: File;
  private bytes: string;

  senders: Sender[];

  constructor(
    private empresaService: EmpresaService,
    private senderService: SenderService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.empresa = {} as Empresa;
    let id: string = super.getParam('id');
    if (id != null || id != undefined) {
      console.log('carregando empresa id >> ' + id);
      this.isNew = false;
      this.getEmpresa(id);
    }
    this.getSenders();
  }

  getEmpresa(id: string) {
    this.empresaService.getById(id).subscribe(
      empresa => {
        this.empresa = empresa;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  getSenders() {
    this.senderService.getAll().subscribe(
      (senders: Sender[]) => {
        this.senders = senders;
      },
      err => {
        this.alertService.error(err.error.message);
      }
    );
  }

  removerImagem() {
    this.empresa.image = undefined;
  }

  onSubmit(): void {
    console.log("on submit add empresa >> " + JSON.stringify(this.empresa));

    if (this.bytes) {
      console.log('imagem');
      console.log(this.bytes);
      this.empresa.image = this.bytes;
    }

    if (this.isNew) {
      this.empresaService.create(this.empresa).subscribe(
        empresa => {
          super.goBack();
          this.alertService.success('Operação realizada com sucesso');
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    } else {
      this.empresaService.update(this.empresa).subscribe(
        empresa => {
          super.goBack();
          this.alertService.success('Operação realizada com sucesso');
        },
        err => {
          this.alertService.error(err.error.message);
        }
      );
    }
  }

}