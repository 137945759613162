
export class FilaHelper {

  public chamadasMap: Map<string, string>;

  constructor() {
    this.chamadasMap = new Map<string, string>();
    this.setupNomesChamadas();
  }

  public getTipoChamada(tipoVoz: string): Array<string> {
    const result = {
      'MALE': this.getTipoChamadaMasculino(),
      'FEMALE': this.getTipoChamadaFeminino(),
    };
    return result[tipoVoz];
  }

  private getTipoChamadaMasculino() {
    const list: Array<string> = new Array<string>();
    list.push('CAIXA');
    list.push('ESCRITORIO');
    list.push('ESTOQUE');
    list.push('EXPEDICAO');
    list.push('GUICHE');
    list.push('PORTAO');
    list.push('SALA');
    list.push('ALA');
    list.push('ALMOXAFIRADO_1');
    list.push('ALMOXAFIRADO_2');
    list.push('ALMOXAFIRADO_3');
    list.push('AMBULATORIO_1');
    list.push('AMBULATORIO_2');
    list.push('AMBULATORIO_3');
    list.push('AVALIACAO_1');
    list.push('AVALIACAO_2');
    list.push('AVALIACAO_3');
    list.push('CLINICA_1');
    list.push('CLINICA_2');
    list.push('CLINICA_3');
    list.push('CONSULTA_1');
    list.push('CONSULTA_2');
    list.push('CONSULTORIO_2');
    list.push('CONSULTORIO_3');
    list.push('EMERGENCIA_1');
    list.push('EMERGENCIA_2');
    list.push('ENFERMARIA_1');
    list.push('ENFERMARIA_2');
    list.push('EXAMES_1');
    list.push('EXAMES_2');
    list.push('LABORATORIO_1');
    list.push('LABORATORIO_2');
    list.push('LABORATORIO_3');
    list.push('MATRICULA_1');
    list.push('MATRICULA_2');
    list.push('MATRICULA_3');
    list.push('PORTARIA_1');
    list.push('PORTARIA_2');
    list.push('PORTARIA_3');
    list.push('RECEPCAO_1');
    list.push('RECEPCAO_2');
    list.push('TRIAGEM_1');
    list.push('TRIAGEM_2');
    list.push('TRIAGEM_3');
    list.push('MESA');
    list.push('SENHA');
    return list;
  }

  private getTipoChamadaFeminino() {
    const list: Array<string> = new Array<string>();
    list.push('CAIXA');
    list.push('ALA');
    list.push('ALMOXARIFADO');
    list.push('AMBULATORIO');
    list.push('AVALIACAO');
    list.push('BILHETERIA');
    list.push('BLOCO');
    list.push('CAIXA');
    list.push('CLINICA');
    list.push('CONSULTA');
    list.push('CONSULTORIO');
    list.push('CORREDOR');
    list.push('DIRETORIA');
    list.push('EMERGENCIA');
    list.push('ENFERMARIA');
    list.push('ESCRITORIO');
    list.push('ESTAND');
    list.push('ESTOQUE');
    list.push('EXAME');
    list.push('EXPEDICAO');
    list.push('FINANCEIRO');
    list.push('GUICHE');
    list.push('LABORATORIO');
    list.push('MATRICULA');
    list.push('MESA');
    list.push('PORTAO');
    list.push('PORTARIA');
    list.push('PRONTO_SOCORRO');
    list.push('RECEPCAO');
    list.push('RECEPCIONISTA');
    list.push('SAGUAO');
    list.push('SALA_DE_ESPERA');
    list.push('SALA_DE_EXAME');
    list.push('SALA');
    list.push('SECAO');
    list.push('SECRETARIA');
    list.push('SENHA');
    list.push('SETOR_FINANCEIRO');
    list.push('SETOR');
    list.push('TORRE');
    list.push('TRIAGEM');
    return list;
  }

  public getNomeChamada(tipoChamada: string) {
    return this.chamadasMap.get(tipoChamada);
  }

  private setupNomesChamadas() {
    this.chamadasMap.set('CAIXA', 'CAIXA');
    this.chamadasMap.set('ESCRITORIO', 'ESCRITÓRIO');
    this.chamadasMap.set('ESTOQUE', 'ESTOQUE');
    this.chamadasMap.set('EXPEDICAO', 'EXPEDIÇÃO');
    this.chamadasMap.set('GUICHE', 'GUICHÊ');
    this.chamadasMap.set('PORTAO', 'PORTÃO');
    this.chamadasMap.set('SALA', 'SALA');
    this.chamadasMap.set('ALA', 'ALA');
    this.chamadasMap.set('ALMOXAFIRADO_1', 'ALMOXAFIRADO');
    this.chamadasMap.set('ALMOXAFIRADO_2', 'ALMOXAFIRADO');
    this.chamadasMap.set('ALMOXAFIRADO_3', 'ALMOXAFIRADO');
    this.chamadasMap.set('AMBULATORIO_1', 'AMBULATÓRIO');
    this.chamadasMap.set('AMBULATORIO_2', 'AMBULATÓRIO');
    this.chamadasMap.set('AMBULATORIO_3', 'AMBULATÓRIO');
    this.chamadasMap.set('AVALIACAO_1', 'AVALIAÇÃO');
    this.chamadasMap.set('AVALIACAO_2', 'AVALIAÇÃO');
    this.chamadasMap.set('AVALIACAO_3', 'AVALIAÇÃO');
    this.chamadasMap.set('CLINICA_1', 'CLÍNICA');
    this.chamadasMap.set('CLINICA_2', 'CLÍNICA');
    this.chamadasMap.set('CLINICA_3', 'CLÍNICA');
    this.chamadasMap.set('CONSULTA_1', 'CONSULTA');
    this.chamadasMap.set('CONSULTA_2', 'CONSULTA');
    this.chamadasMap.set('CONSULTORIO_2', 'CONSULTÓRIO');
    this.chamadasMap.set('CONSULTORIO_3', 'CONSULTÓRIO');
    this.chamadasMap.set('EMERGENCIA_1', 'EMERGÊNCIA');
    this.chamadasMap.set('EMERGENCIA_2', 'EMERGÊNCIA');
    this.chamadasMap.set('ENFERMARIA_1', 'ENFERMARIA');
    this.chamadasMap.set('ENFERMARIA_2', 'ENFERMARIA');
    this.chamadasMap.set('EXAMES_1', 'EXAMES');
    this.chamadasMap.set('EXAMES_2', 'EXAMES');
    this.chamadasMap.set('LABORATORIO_1', 'LABORATÓRIO');
    this.chamadasMap.set('LABORATORIO_2', 'LABORATÓRIO');
    this.chamadasMap.set('LABORATORIO_3', 'LABORATÓRIO');
    this.chamadasMap.set('MATRICULA_1', 'MATRÍCULA');
    this.chamadasMap.set('MATRICULA_2', 'MATRÍCULA');
    this.chamadasMap.set('MATRICULA_3', 'MATRÍCULA');
    this.chamadasMap.set('PORTARIA_1', 'PORTARIA');
    this.chamadasMap.set('PORTARIA_2', 'PORTARIA');
    this.chamadasMap.set('PORTARIA_3', 'PORTARIA');
    this.chamadasMap.set('RECEPCAO_1', 'RECEPÇÃO');
    this.chamadasMap.set('RECEPCAO_2', 'RECEPÇÃO');
    this.chamadasMap.set('TRIAGEM_1', 'TRIAGEM');
    this.chamadasMap.set('TRIAGEM_2', 'TRIAGEM');
    this.chamadasMap.set('TRIAGEM_3', 'TRIAGEM');
    this.chamadasMap.set('ALMOXARIFADO', 'ALMOXARIFADO');
    this.chamadasMap.set('AMBULATORIO', 'AMBULATÓRIO');
    this.chamadasMap.set('AVALIACAO', 'AVALIAÇÃO');
    this.chamadasMap.set('BILHETERIA', 'BILHETERIA');
    this.chamadasMap.set('BLOCO', 'BLOCO');
    this.chamadasMap.set('CAIXA', 'CAIXA');
    this.chamadasMap.set('CLINICA', 'CLÍNICA');
    this.chamadasMap.set('CONSULTA', 'CONSULTA');
    this.chamadasMap.set('CONSULTORIO', 'CONSULTÓRIO');
    this.chamadasMap.set('CORREDOR', 'CORREDOR');
    this.chamadasMap.set('DIRETORIA', 'DIRETORIA');
    this.chamadasMap.set('EMERGENCIA', 'EMERGÊNCIA');
    this.chamadasMap.set('ENFERMARIA', 'ENFERMARIA');
    this.chamadasMap.set('ESCRITORIO', 'ESCRITÓRIO');
    this.chamadasMap.set('ESTAND', 'ESTANDE');
    this.chamadasMap.set('ESTOQUE', 'ESTOQUE');
    this.chamadasMap.set('EXAME', 'EXAME');
    this.chamadasMap.set('FINANCEIRO', 'FINANCEIRO');
    this.chamadasMap.set('LABORATORIO', 'LABORATÓRIO');
    this.chamadasMap.set('MATRICULA', 'MATRÍCULA');
    this.chamadasMap.set('MESA', 'MESA');
    this.chamadasMap.set('PORTARIA', 'PORTARIA');
    this.chamadasMap.set('PRONTO_SOCORRO', 'PRONTO-SOCORRO');
    this.chamadasMap.set('RECEPCAO', 'RECEPÇÃO');
    this.chamadasMap.set('RECEPCIONISTA', 'RECEPCIONISTA');
    this.chamadasMap.set('SAGUAO', 'SAGUÃO');
    this.chamadasMap.set('SALA_DE_ESPERA', 'ESPERA');
    this.chamadasMap.set('SALA_DE_EXAME', 'EXAME');
    this.chamadasMap.set('SECAO', 'SEÇÃO');
    this.chamadasMap.set('SECRETARIA', 'SECRETARIA');
    this.chamadasMap.set('SENHA', 'SENHA');
    this.chamadasMap.set('SETOR_FINANCEIRO', 'FINANCEIRO');
    this.chamadasMap.set('SETOR', 'SETOR');
    this.chamadasMap.set('TORRE', 'TORRE');
    this.chamadasMap.set('TRIAGEM', 'TRIAGEM');
  }

}