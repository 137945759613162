export class Pager<T> {

  public page: number = 1;
  public previousPage: number = 1;
  public perPage: number = 10;
  public total: number;
  public list: T[] = [];

  constructor(values: object = {}) {
    Object.assign(this, values);
  }

}
