import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { io } from 'socket.io-client';
import { ENV, Environment } from '../commons/injection.token';

@Injectable({ providedIn: 'root' })
export class SocketService {

  socket: any;
  socketConnected$ = new BehaviorSubject<boolean>(false);

  constructor(@Inject(ENV) private environment: Environment) {
  }

  public connect() {
    this.socket = io(String(this.environment['socketUrl']), { reconnection: true });
    this.socket.on('connect', () => this.socketConnected$.next(true));
    this.socket.on('disconnect', () => this.socketConnected$.next(false));
    this.socketConnected$.asObservable().subscribe(connected => {
      console.log('Socket connected: ', connected);
    });
  }

  public disconnect() {
    // this.socket.emit('disconnect', true);
    // this.socket.close();
  }

  public listen(event: string): Observable<any> {
    return new Observable(observer => {
      this.socket.on(event, data => {
        observer.next(data);
      });
      return () => {
        this.socket.off(event);
      }
    });
  }

}
