<div class=" wrapper wrapper-content animated fadeIn">

  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Sender</h5>
          <div class="ibox-tools">
            <a [routerLink]="['/sender/add']" class="btn btn-primary btn-xs">
              <i class="fa fa-plus"></i> Adicionar</a>
          </div>
        </div>
        <div class="ibox-content">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th width="35%">Nome</th>
                  <th>Tipo</th>
                  <th>Implementação</th>
                  <th width="10%" style="text-align: right">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of senders; let i = index">
                  <td>{{item.name}}</td>
                  <td>{{item.type}}</td>
                  <td>{{item.impl }}</td>
                  <td style="text-align: right">
                    <a class="btn btn-white btn-sm btn-bitbucket" [routerLink]="['/sender/edit/', item._id]">
                      <i class="fa fa-edit"></i>
                    </a>
                    <a class="btn btn-danger btn-sm btn-bitbucket" data-toggle="modal" href="#deleteModalSender"
                      (click)="selecionar(item)">
                      <i class="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="modal inmodal" id="deleteModalSender" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">
          <span aria-hidden="true">&times;</span>
          <span class="sr-only">Close</span>
        </button>
        <h4 class="modal-title">Desassociar Estabelecimento</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita. Tem certeza que deseja desassociar o estabelecimento
            <b>{{senderSelecionado?.name}}</b>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Cancelar</button>
        <button type="button" class="btn btn-primary" (click)="delete()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>