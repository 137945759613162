import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Aviso } from '../models/aviso.model';

@Injectable({ providedIn: 'root' })
export class AvisoService extends AbstractService<Aviso>  {

  getURLBase(): string {
    return '/avisos/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Aviso): void {
    delete obj._id;
  }
  preUpdate(obj: Aviso): void {
  }

  getPagerAvisosPorGrupo(page: number, perPage: number, idGrupo: string): Observable<Aviso[] | any> {
    return super.get('pager/grupo/' + page + '/' + perPage + '/' + idGrupo);
  }

  getPagerAvisosPorFranquia(page: number, perPage: number, idFranquia: string): Observable<Aviso[] | any> {
    return super.get('pager/franquia/' + page + '/' + perPage + '/' + idFranquia);
  }

  getPagerAvisosPorEmpresa(page: number, perPage: number, idEmpresa: string): Observable<Aviso[] | any> {
    return super.get('pager/empresa/' + page + '/' + perPage + '/' + idEmpresa);
  }

  getListAvisosFranquia(idFranquia: string): Observable<Aviso[] | any> {
    return super.get('list/ativos/franquia/' + idFranquia);
  }

  getListAvisosGrupo(idGrupo: string): Observable<Aviso[] | any> {
    return super.get('list/ativos/grupo/' + idGrupo);
  }

  getAvisosPorEmpresa(page: number, perPage: number, idEmpresa: string): Observable<Aviso[] | any> {
    return super.get('pager/empresa/' + page + '/' + perPage + '/' + idEmpresa);
  }

  ativar(idAviso: string): Observable<Aviso[] | any> {
    return super.get('ativar/' + idAviso);
  }

  desativar(idAviso: string): Observable<Aviso[] | any> {
    return super.get('desativar/' + idAviso);
  }

}