import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractService } from '../commons/abstract.service';
import { Questionario } from '../models/questionario.model';

@Injectable({ providedIn: 'root' })
export class QuestionarioService extends AbstractService<Questionario> {

  getURLBase(): string {
    return '/questionarios/';
  }

  constructor(private http: HttpClient) {
    super(http);
  }

  preCreate(obj: Questionario): void {
    delete obj._id;
  }
  preUpdate(obj: Questionario): void {
  }

  getAllPorEmpresa(idEmpresa: string): Observable<Questionario[] | any> {
    return super.get('list/' + idEmpresa);
  }

  getAllPorEmpresaPager(page: number, perPage: number, idEmpresa: string): Observable<Questionario[] | any> {
    return super.get('pager/' + page + '/' + perPage + '/empresa/' + idEmpresa);
  }

}
