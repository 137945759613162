<div class="row">
  <div class="col-lg-12">
    <div class="ibox float-e-margins">
      <div class="ibox-title">
        <h5>Usuários</h5>
        <div class="ibox-tools">
          <!-- <a [routerLink]="['/usuario/add/empresa', idEmpresa]" class="btn btn-primary" *ngIf="tipo === 'empresa'">
            <i class="fa fa-plus"></i> Novo Usuário Empresa</a>
          <a [routerLink]="['/usuario/add/grupo', idGrupo]" class="btn btn-primary" *ngIf="tipo === 'grupo'">
            <i class="fa fa-plus"></i> Novo Usuário Grupo
          </a> -->
          <!-- <input type="file" class="form-control" (change)="uploadedFile($event)" placeholder="Upload file"
            accept=".xlsx"> -->
        </div>
      </div>
      <div class="ibox-content">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Foto</th>
                <th width="50%">Nome</th>
                <th>Email</th>
                <th *ngIf="tipo === 'empresa'">Função</th> <!-- todos usuarios do grupo sao admin -->
                <th>Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let usuario of usuarios">
                <td>
                  <img class="img-circle" src="{{getUsuarioImage(usuario)}}" style="width: 42px;" />
                </td>
                <td>{{usuario.nome}}</td>
                <td>{{usuario.email}}</td>
                <td *ngIf="tipo === 'empresa'">{{usuario.admin ? 'Administrador' : 'Atendente' }}</td>
                <td>
                  <span class="label label-primary" *ngIf="!usuario.bloqueado">ATIVO</span>
                  <span class="label label-danger" *ngIf="usuario.bloqueado">BLOQUEADO</span>
                </td>
                <td class="text-right">
                  <a *ngIf="!usuario.bloqueado" class="btn btn-danger btn-sm btn-bitbucket" title="Bloquear"
                    data-toggle="modal" data-target="#modalBlockUsuarioList" (click)="selecionar(usuario)">
                    <i class="fa fa-times-circle"></i>
                  </a>
                  <a *ngIf="usuario.bloqueado" class="btn btn-danger   btn-sm btn-bitbucket" title="Desbloquear"
                    data-toggle="modal" data-target="#modalUnblockUsuarioList" (click)="selecionar(usuario)">
                    <i class="fa fa-check-circle"></i>
                  </a>
                  <a class="btn btn-warning btn-sm btn-bitbucket" [routerLink]="['/usuario/edit/senha', usuario._id]">
                    <i class="fa fa-lock"></i>
                  </a>
                  <a class="btn btn-info btn-sm btn-bitbucket" (click)="loginImpersonate(usuario.email)">
                    <i class="fa fa-sign-in"></i>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal inmodal" id="modalBlockUsuarioList" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Bloquear Usuário</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Tem certeza que deseja <strong>BLOQUEAR</strong> o usuário <strong>{{usuarioSelecionado?.nome}}</strong>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="bloquear()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modalUnblockUsuarioList" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Desbloquear Usuário</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Tem certeza que deseja <strong>DESBLOQUEAR</strong> o usuário <strong>{{usuarioSelecionado?.nome}}</strong>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="desbloquear()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal inmodal" id="modalRemoveUsuarioList" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content animated bounceInRight">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"><span aria-hidden="true">&times;</span><span
            class="sr-only">Close</span></button>
        <h4 class="modal-title">Remover Usuário</h4>
      </div>
      <div class="modal-body">
        <div align="center">
          <p>
            Esta ação não poderá ser desfeita! Tem certeza que deseja <strong>REMOVER</strong> o usuário
            <strong>{{usuarioSelecionado?.nome}}</strong>?
          </p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-white" data-dismiss="modal">Fechar</button>
        <button type="button" class="btn btn-primary" (click)="delete()" data-dismiss="modal">Confirmar</button>
      </div>
    </div>
  </div>
</div>