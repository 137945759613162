import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, AuthenticationService } from 'lib-smart-core';

@Component({

  templateUrl: 'login.component.html'
})

export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService) { }

  ngOnInit() {
    this.authenticationService.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  login() {
    this.loading = true;
    const email: string = this.model.email;
    const senha: string = this.model.senha;

    const domains = ['@smartline.com.br', '@smartlinepro.com.br'];
    const domainEmail = email.substring(email.indexOf('@'), email.length);

    if (!domains.includes(domainEmail)) {
      this.alertService.error('Você não permissão para acessar este módulo.');
      this.loading = false;
      return;
    }

    this.authenticationService.login(email, senha).subscribe(
      data => {
        this.loading = false;
        this.router.navigate([this.returnUrl]);
      },
      error => {
        this.alertService.error('Usuário inválido. Email e/ou senha estão incorretos ou você não tem acesso. Entre em contato com a equipe de suporte.');
        this.loading = false;
        this.router.navigate(['/login']);
      }
    );
  }
}
