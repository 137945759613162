<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-12">
    <h2>Empresa</h2>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/dashboard']">Dashboard</a>
      </li>
      <li>
        <a [routerLink]="['/empresa/detail', usuario.empresa]">Empresa</a>
      </li>
      <li>
        Usuario
      </li>
      <li class="active">
        <strong *ngIf="isNew">Adicionar</strong>
        <strong *ngIf="!isNew">Alterar</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-8">
      <div class="ibox float-e-margins">

        <div class="ibox-content">
          <form (ngSubmit)="onSubmit()" #usuarioForm="ngForm" novalidate>
            <div class="form-group">
              <label>Nome</label>
              <input type="text" placeholder="Insira o Nome do Usuário" class="form-control" name="nome" id="nome"
                #nome="ngModel" [(ngModel)]="usuario.nome" required>
            </div>
            <div class="form-group">
              <label>Email</label>
              <input type="email" placeholder="Insira o Email do Usuário" class="form-control" name="email" id="email"
                #email="ngModel" [(ngModel)]="usuario.email" required>
            </div>

            <div class="form-group" *ngIf="!usuarioDeGrupo">
              <label>Administrador</label>
              <input type="radio" [(ngModel)]="usuario.admin" name="admin" id="admin" [value]="true">Sim
              <input type="radio" [(ngModel)]="usuario.admin" name="admin" id="admin" [value]="false">Não
            </div>

            <div class="hr-line-dashed"></div>
            <div class="form-group">
              <button type="submit" class="btn btn-primary" [disabled]="!usuarioForm.form.valid">Salvar</button>
              <button type="button" class="btn btn-white" (click)="goBack()">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Alterar Imagem Usuário</h5>
        </div>
        <div class="ibox-content">
          <div class="form-group" style="display: none">
            <input type="file" class="form-control" id="localImage" (change)="onChange($event)" accept=".jpg, .png">
          </div>
          <div class="panel-body text-center">
            <img *ngIf="usuario.image" src="{{usuario.image}}" style="max-width: 100%;" />
          </div>
          <hr class="m-y-0">
          <div class="panel-body text-center">
            <button type="button" class="btn btn-primary" (click)="openFileBrowser($event)">Alterar</button>&nbsp;
            <button type="button" class="btn" (click)="removerImagem()">
              <i class="fa fa-trash"></i>
            </button>
            <div class="m-t-2 text-muted font-size-12">JPG ou PNG. Tamano máximo 500 KB</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>