import { Component, Injector, OnInit } from '@angular/core';
import { AbstractComponent, Empresa, EmpresaService, FirebaseService, Franquia, FranquiaService, Sender, UploadTypeEnum } from 'lib-smart-core';

@Component({
  selector: 'franquia-lista',
  templateUrl: './franquia-lista.component.html'
})
export class FranquiaListaComponent extends AbstractComponent implements OnInit {

  empresa: Empresa;
  idEmpresa: string;
  franquias: Franquia[];
  sender: Sender;

  franquiaSelecionada: Franquia;

  constructor(
    private empresaService: EmpresaService,
    private franquiaService: FranquiaService,
    private firebaseService: FirebaseService,
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    let idEmpresa: string = super.getParam('idEmpresa');
    if (idEmpresa) {
      this.idEmpresa = idEmpresa;
      this.loadEmpresa();
      this.getFranquiasPorEmpresa(idEmpresa.toString());
    }
  }
  loadEmpresa() {
    this.empresaService.getById(this.idEmpresa).subscribe(
      (empresa: Empresa) => {
        this.empresa = empresa;
        this.sender = empresa.sender;
      }, err => this.alertService.error(err.error.message)
    );
  }

  getFranquiasPorEmpresa(idEmpresa: string) {
    return this.franquiaService.getFranquiasPorEmpresa(idEmpresa).subscribe(
      (franquia: Franquia[]) => {
        this.franquias = franquia
      },
      err => {
        this.alertService.error(err.error.message);
      }
    )
  }

  selecionar(franquia: Franquia) {
    this.franquiaSelecionada = franquia;
  }

  delete(): void {
    this.franquiaService.delete(this.franquiaSelecionada._id).subscribe(
      () => {
        this.getFranquiasPorEmpresa(this.empresa._id);
        this.alertService.success('Operação realizada com sucesso');
      },
      err => {
        console.log(err);
        this.alertService.error(err.error.message);
      }
    );
  }

  private file: File;

  openFileBrowser(event: any) {
    event.preventDefault();
    let element: HTMLElement = document.getElementById('localImage') as HTMLElement;
    element.click();
  }

  onChange(event) {
    let eventObj: any = <any>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!(files[0].type === 'image/jpeg' || files[0].type === 'image/png')) {
      this.alertService.warning('Imagem inválida. O arquivo de imagem deve estar no formato JPG ou PNG.');
      return;
    }

    this.file = files[0];
    // console.log('onChange');
    // console.log(this.file);

    let reader = new FileReader();
    reader.onload = file => {
      if (this.file) {
        console.log('uploading.... ' + this.empresa._id);
        let filename = this.empresa._id + '_' + Date.now();
        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.EMPRESA).then(snapshot => {
          console.log(snapshot.downloadURL);
          this.empresa.image = snapshot.downloadURL;
          this.empresaService.update(this.empresa).subscribe(
            empresa => {
              this.alertService.success('Operação realizada com sucesso');
            },
            err => this.alertService.error(err.error.message)
          );
        });
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  removerImagem() {
    // this.firebaseService.deleteFile(this.empresa.image).then(snapshot => {
    //   this.empresa.image = null;
    //   this.empresaService.update(this.empresa).subscribe(
    //     empresa => {
    //       this.alertService.success('Operação realizada com sucesso');
    //     },
    //     err => this.alertService.error(err.error.message)
    //   );
    // });
  }

  bloquear() {
    this.franquiaService.bloquear(this.franquiaSelecionada._id).subscribe(
      empresa => {
        this.alertService.success('Operação realizada com sucesso');
        this.getFranquiasPorEmpresa(this.empresa._id);
      },
      err => this.alertService.error(err.error.message)
    );
  }

  desbloquear() {
    this.franquiaService.desbloquear(this.franquiaSelecionada._id).subscribe(
      empresa => {
        this.alertService.success('Operação realizada com sucesso');
        this.getFranquiasPorEmpresa(this.empresa._id);
      },
      err => this.alertService.error(err.error.message)
    );
  }

}