import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-smart-core';
import { FranquiaFormsComponent } from "./franquia-forms.component";
import { FranquiaListaComponent } from "./franquia-lista.component";

@NgModule({
  imports: [
    SharedModule
  ],
  declarations: [
    FranquiaListaComponent,
    FranquiaFormsComponent
  ],
  exports: [
    FranquiaListaComponent,
    FranquiaFormsComponent
  ],
  providers: []
})

export class FranquiaModule { }